import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { clienteAxios, AuthContext } from "./";
import PlanillaResumen from "./PlanillaResumen";

const PanelAdmin = () => {
  const [loadingLocal, setLoadingLocal] = useState(null);
  const authContext = useContext(AuthContext);
  const { usuario } = authContext;

  const [consultar, setConsultar] = useState(true);
  const [itemSeleccionado, setitemSeleccionado] = useState(null);

  useEffect(() => {
    const cargar = async () => {
      if (!consultar) return;

      try {
        setLoadingLocal(true);
        const result = await clienteAxios.get("/Subastas/get", {
          params: {
            id: localStorage.getItem("idsubasta")
              ? localStorage.getItem("idsubasta")
              : "0",
            idproducto: !localStorage.getItem("idproducto")
              ? "0"
              : localStorage.getItem("idproducto"),
            idsolicitud: !localStorage.getItem("idsolicitud")
              ? "0"
              : localStorage.getItem("idsolicitud"),
          },
        });
        console.log(result.data);
        setitemSeleccionado({ subasta: result.data.subasta });
        setLoadingLocal(null);
      } catch (e) {}
      setConsultar(null);
    };

    cargar();
  }, [consultar, itemSeleccionado]);

  return (
    <div className="p-2 row justify-content-center ">
      <h2>Panel Subastas</h2>
      {usuario.esadmin === "1" ? (
        <div className="card" style={{ width: "90%", margin: "10px" }}>
          <div className="card-body">
            <h5 className="card-title">Configuraciones</h5>
            <div className="card-text">
              <Link to="/admin/subastas/">Ingresar</Link>
              <br></br>
            </div>
          </div>
        </div>
      ) : null}

      <div className="card" style={{ width: "90%", margin: "10px" }}>
        <div className="card-body">
          <h5 className="card-title">Resumen </h5>
          <div className="card-text">
          <PlanillaResumen id={localStorage.getItem("idsubasta")} idetapa="2"/>
            <PlanillaResumen id={localStorage.getItem("idsubasta")} idetapa="8"/>
          </div>
        </div>
      </div>
      {/* <div className="card" style={{ width: "90%", margin: "10px" }}>
        <div className="card-body">
          <h5 className="card-title">Proveedores </h5>
          <div className="card-text"></div>
        </div>
      </div>
      <div className="card" style={{ width: "90%", margin: "10px" }}>
        <div className="card-body">
          <h5 className="card-title">Compradores </h5>
          <p className="card-text"></p>
        </div>
      </div> */}
    </div>
  );
};

export default PanelAdmin;
