import React from "react";
import Item from "./UsuarioRubro";
import { TituloTabla } from "../layout/Estilos";
import { clienteAxios, Swal } from "./";

const ListarUsuarioRubros = ({
  emails,
  setemailSeleccionado,
  setConsultar,
  openModal,
  soloLectura,
  tipo,
}) => {
  const borrar = async (id) => {
    try {
      await clienteAxios.delete("/UserRubros/remove", {
        params: { id },
      });
      setConsultar(true);
    } catch (e) {}
  };

  const editar = (email) => {
    setemailSeleccionado(email);
    openModal();
  };

  const eliminar = (email) => {
    Swal.fire({
      title: "Esta Seguro que desea Eliminar?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        borrar(email.id);
      }
    });
  };

  return (
    <div className="table-responsive">
      <table
        className="table table-striped"
        style={{ backgroundColor: "white" }}
      >
        <TituloTabla>
          <tr>
            <th scope="col" className="text-center">
              Rubro
            </th>
            {tipo === "1" ? (
              <th scope="col" className="text-center">
                Activo
              </th>
            ) : null}
           {tipo==="1" ? <th scope="col" className="text-center">
              Acciones
            </th> : null}
          </tr>
        </TituloTabla>
        <tbody>
          {emails.map((item) => (
            <Item
              key={item.id}
              item={item}
              editar={editar}
              eliminar={eliminar}
              soloLectura={soloLectura}
              tipo={tipo}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListarUsuarioRubros;
