import React from 'react';

const Oferente = ({item}) => {
    return (
        <tr>
      <td className="text-center">{item.razonsocial}</td>
      <td className="text-center">{item.cantidad}</td>
    </tr>
    );
};

export default Oferente;