import React, { useState, useEffect, useContext } from "react";
import Listar from "./ListarMisSubastas";
import { Spinner, clienteAxios, AuthContext, useHistory } from "..";
import { Boton } from "../../layout/Estilos";

const MisSubastas = () => {
  const history = useHistory();

  const authContext = useContext(AuthContext);
  const { usuario } = authContext;

  const [loadingLocal, setLoadingLocal] = useState(null);
  const [items, setItems] = useState({ compradores: [], proveedor: [] });

  const [consultar, setConsultar] = useState(true);
  const [itemSeleccionado, setItemSeleccionado] = useState(null);

  useEffect(() => {
    const cargar = async () => {
      localStorage.removeItem("idproducto");
      localStorage.removeItem("nombre");
      localStorage.removeItem("idrubro");
      localStorage.removeItem("idsolicitud");
      localStorage.removeItem("idsubasta");

      if (!consultar) return;

      try {
        setLoadingLocal(true);
        const result = await clienteAxios.get("/Subastas/getAllMisSubastas", {
          params: { idusuario: usuario.id },
        });
        setItems({
          compradores: result.data.compradores,
          proveedor: result.data.proveedor,
        });
        setLoadingLocal(null);
      } catch (e) {}
      setConsultar(null);
    };

    cargar();
  }, [consultar, itemSeleccionado]);

  return (
    <div className="center-block">
      <h2>Mis Subastas</h2>
      <br></br>
      <p></p>
      {usuario.esadmin === "1" ? (
        <div className="row">
          <div className="col-sm">
            <div className="form-group">
              {/* <Boton
                onClick={() => {
                  history.push("admin/solicitudes");
                }}
                className="btn btn-dark"
              >
                <i className="fa fa-plus-circle"></i> Agregar Subasta desde
                Solicitud
              </Boton>{" "} */}
              <Boton
                onClick={() => {
                  history.push("admin/productos");
                }}
                className="btn btn-dark"
              >
                <i className="fa fa-plus-circle"></i> Agregar Subasta desde
                Oferta
              </Boton>
   {/*            <Boton
                onClick={() => {
                  history.push("admin/subastas");
                }}
                className="btn btn-dark"
              >
                <i className="fa fa-plus-circle"></i> Agregar Subasta desde
                Oferta
              </Boton> */}
            </div>
          </div>
          <br></br>
        </div>
      ) : null}
      <br></br>
      <p>Subastas {usuario.esadmin === "0" ? "Como Comprador" : ""}</p>
      {loadingLocal ? (
        <Spinner />
      ) : (
        <Listar
          items={items.compradores}
          setItemSeleccionado={setItemSeleccionado}
          setConsultar={setConsultar}
          usuario={usuario}
          tipo={ usuario.esadmin === "0" ? "comprador" : "admin"}
        />
      )}
      <p></p>
      {usuario.esadmin === "0" ? (
        <>
          <hr></hr>
          <p></p>
          <p>Subastas Como Proveedor</p>
          {loadingLocal ? (
            <Spinner />
          ) : (
            <Listar
              items={items.proveedor}
              setItemSeleccionado={setItemSeleccionado}
              setConsultar={setConsultar}
              usuario={usuario}
              tipo={"proveedor"}
            />
          )}
        </>
      ) : null}
    </div>
  );
};

export default MisSubastas;
