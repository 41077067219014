import React, { useState} from "react";
import { Input, } from "../../../layout/FormsElements";

const DefinicionDemanda = ({ DatosForm, onChange }) => {
  const [soloLectura] = useState(false);

  const { cantidadperfil1, cantidadperfil2, cantidadperfil3 } = DatosForm;

  return (
    <div>
    {/*   <Boton className="btn btn-lg" style={{ margin: "10px" }}>
        Ver Intereses de la Demanda
      </Boton> */}
      <br></br>
      <b>Cantidad Minima:</b>

      <Input
        key={"cantidadperfil1"}
        sets={{
          label: "Cantidad",
          type: "number",
          name: "cantidadperfil1",
          placeholder: " ",
          valor: cantidadperfil1,
          disabled: soloLectura,
        }}
        onChange={onChange}
      />

      <hr></hr>
      <b>Cantidad Media:</b>

      <Input
        key={"cantidadperfil2"}
        sets={{
          label: "Cantidad",
          type: "number",
          name: "cantidadperfil2",
          placeholder: " ",
          valor: cantidadperfil2,
          disabled: soloLectura,
        }}
        onChange={onChange}
      />

      <hr></hr>
      <b>Cantidad Maxima:</b>

      <Input
        key={"cantidadperfil3"}
        sets={{
          label: "Cantidad",
          type: "number",
          name: "cantidadperfil3",
          placeholder: " ",
          valor: cantidadperfil3,
          disabled: soloLectura,
        }}
        onChange={onChange}
      />
    </div>
  );
};

export default DefinicionDemanda;
