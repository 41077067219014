import React from "react";

const ResumenEtapaUsuario = ({
  etapa,
  idetapa,
  openModal,
  setproductoSeleccionado,
}) => {
  const estado = (idetapa) => {
    switch (idetapa) {
      case "1":
        return "PROGRAMADA";
      case "2":
        return "RECEPCION DE OFERTAS";
      case "3":
        return "EN ETAPA 1";
      case "45":
        return "EN ETAPA 2";
      case "67":
        return "EN ETAPA 3";
      case "8":
        return "SUBASTA FINALIZADA";
      default:
        break;
    }
  };

  if (etapa.length === 0) return <></>;

  return (
    <div className="card" style={{ width: "90%", margin: "10px" }}>
      <div className="card-body">
        Etapa: {estado(idetapa)}
        <div className="card-text">
          <br></br>
          <div className="table-responsive">
            <table className="table" style={{ backgroundColor: "white" }}>
              <thead>
                <tr>
                  <th className="text-center">Articulo</th>
                  <th className="text-center">Cantidad Solictada</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {etapa.map((item) => (
                  <tr key={item.id}>
                    <th className="text-center">{item.nombre}</th>
                    <th className="text-center">{item.total}</th>
                    <td className="text-center">
                      {idetapa === "8" ? (
                        <button
                          onClick={() => {
                            openModal();
                            setproductoSeleccionado(item);
                            console.log(item.id);
                          }}
                        >
                          Ver Detalle
                        </button>
                      ) : null}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResumenEtapaUsuario;
