import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Boton } from "../layout/Estilos";
import { AuthContext, useAlerta, useRest, Spinner } from "./";
import styled from "@emotion/styled";

//xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

const ContenedorLogin = styled.div`
@media only screen and (max-width: 600px) {
    width: 100%;
    margin: 0;
    min-height: 600px;
  }

  @media (min-width: 600px) {
    width: 30%;
    margin: auto;
    min-height: 600px;
  }
`;



const Login = (props) => {
  const authContext = useContext(AuthContext);
  const {
    autenticado,
    iniciarSesion,
    enviarActivarCuenta,
    ocultarMensaje,
    mensaje,
  } = authContext;

  const [setAlerta, MostrarAlerta] = useAlerta(null);
  const [loading, load] = useRest();
  const [inicio, setInicio] = useState(true);
  const [mostrarReenviar, setMostrarReenviar] = useState(null);

  useEffect(() => {
    if (autenticado) {
      ocultarMensaje();
      // eslint-disable-next-line

      props.history.push("/dashboard");
    }

    const alertar = () => {
      if (loading) {
        setAlerta(null);
        setMostrarReenviar(null);
      }
      if (inicio) {
        // eslint-disable-next-line
        ocultarMensaje();
        setInicio(null);
        setAlerta(null);
        setMostrarReenviar(null);
      } else {
        if (mensaje.msg && !loading) {
          setAlerta({ msg: mensaje.msg, class: mensaje.class });
          if (mensaje.msg === "Debe validar su dirección de correo") {
            setMostrarReenviar(true);
          }
        }
      }
    };

    alertar();
  }, [
    autenticado,
    mensaje,
    loading,
    inicio,
    setAlerta,
    props.history,
    ocultarMensaje,
  ]);

  const [DatosForm, LeerForm] = useState({
    email: "",
    password: "",
  });
  const { email, password } = DatosForm;

  const onChange = (e) => {
    LeerForm({
      ...DatosForm,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (email.trim() === "" || password.trim() === "") {
      setAlerta({ msg: "Todos los campos son obligatorios", class: "danger" });
      return;
    } else {
      const api = {
        url: "/auth",
        type: "post",
        datosJson: {
          email,
          password,
        },
        getParams: null,
        funcion: iniciarSesion,
      };

      load(api);
    }
  };

  const onClickEnviarActivarCuenta = () => {
    if (email.trim() === "") {
      setAlerta({ msg: "El Email es Obligatorio", class: "danger" });
      return;
    } else {
      setAlerta(null);

      const api = {
        url: "/auth/enviaractivar",
        type: "post",
        datosJson: { email },
        getParams: null,
        funcion: enviarActivarCuenta,
      };

      load(api);
    }
  };

  return (
    <>
      <br></br>

      <ContenedorLogin className="row text-center" >
      <h2 className="text-center">Iniciar Sesion</h2>

        <form onSubmit={onSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              className="form-control"
              id="email"
              aria-describedby="emailHelp"
              placeholder="Ingrese su Email"
              onChange={onChange}
              value={email}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Contraseña</label>
            <input
              type="password"
              name="password"
              id="password"
              className="form-control"
              aria-describedby="emailHelp"
              placeholder="Ingrese su Contraseña"
              onChange={onChange}
              value={password}
            />
          </div>
          <div className="form-group">
            <p className="text-center">
              <br></br>
              Al ingresar acepta nuestros{" "}
              <Link to="/terminos" target="_blank">
                Terminos y Condiciones
              </Link>
            </p>
          </div>

          {loading ? (
            <Spinner />
          ) : (
            <>
              <MostrarAlerta />

              {mostrarReenviar ? (
                <p className="text-center">
                  <Link to="#" onClick={onClickEnviarActivarCuenta}>
                    Re-Enviar Email de Activación de Cuenta
                  </Link>
                </p>
              ) : null}
            </>
          )}

          <div className="form-group">
          <br></br>
            <Boton type="submit" className="btn btn-primary btn-lg">
              Ingresar
            </Boton>
          </div>
        </form>
        <div className="text-center">
        <br></br>
        <br></br>

          <Link aria-label="Obtener Cuenta" to={"/registrate"}>
            Registrarse
          </Link>{" "}
          -{" "}
          <Link aria-label="Olvide mi Contraseña" to={"/olvide"}>
            Olvide mi Contraseña
          </Link>
          <br></br>
          <br></br>
        </div>
      </ContenedorLogin>
    </>
  );
};

export default Login;
