import React from "react";

const PageError = () => {
  return (
    <div className='justify-content-center text-center'>
      <br></br>
      <h2>Pagina No Encontrada</h2>
      <br></br>
    </div>
  );
};

export default PageError;
