import React, { useContext } from "react";
import Header from "./Header";
import Footer from "./Footer";
import styled from "@emotion/styled";
import AuthContext from "../../context/autenticacion/authContext";


const ContenedorFormR = styled.div`
  @media only screen and (max-width: 600px) {
    width: 95%;
    margin: auto;
    min-height: 600px;
  }

  @media (min-width: 600px) {
    margin: auto;
    min-height: 600px;
    width: 90%;
  }
`;

const Layout = ({ children }) => {
  const authContext = useContext(AuthContext);
  const { usuario, cerrarSesion } = authContext;
  return (
    <>
      <Header usuario={usuario} cerrarSesion={cerrarSesion} />

      <div className="container-fluid" style={{ padding: "0" }}>
        <ContenedorFormR>{children}</ContenedorFormR>
      </div>
      <Footer />
    </>
  );
};

export default Layout;
