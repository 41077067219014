import React from "react";
import Item from "./Solicitud";
import { TituloTabla } from "../layout/Estilos";
import { clienteAxios, Swal, useHistory } from ".";

const ListarSolicitudes = ({
  emails,
  setemailSeleccionado,
  setConsultar,
  openModal,
  soloLectura,
  esadmin,
}) => {
  
  const history = useHistory();

  const borrar = async (id) => {
    try {
      await clienteAxios.delete("/Solicitudes/remove", {
        params: { id },
      });
      setConsultar(true);
    } catch (e) {}
  };

  const editar = (email) => {
    setemailSeleccionado(email);
    openModal();
  };

  const eliminar = (email) => {
    Swal.fire({
      title: "Esta Seguro que desea Eliminar?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        borrar(email.id);
      }
    });
  };
  const subasta = (email) => {
    //setemailSeleccionado(email);
    localStorage.removeItem('idproducto');
    localStorage.removeItem('nombre');
    localStorage.removeItem('idrubro');
    localStorage.removeItem('idsolicitud');
    localStorage.removeItem('idsubasta');

    localStorage.setItem('idsolicitud', email.id);
    localStorage.setItem('nombre', email.nombre);
    localStorage.setItem('idrubro', email.idrubro);


    history.push('/admin/gestionsubastas');
  };


  return (
    <div className="table-responsive">
      <table
        className="table table-striped"
        style={{ backgroundColor: "white" }}
      >
        <TituloTabla>
          <tr>
          <th scope="col" className="text-center">
              Nombre
            </th>
            <th scope="col" className="text-center">
              Descripcion
            </th>
            <th scope="col" className="text-center">
              Cantidad
            </th>
            {esadmin === "1" ? (
              <th scope="col" className="text-center">
                Comprador
              </th>
            ) : null}
            {esadmin === "1" ? (
              <th scope="col" className="text-center">
                Interesados
              </th>
            ) : null}
            <th scope="col" className="text-center">
              Estado
            </th>
            <th scope="col" className="text-center">
              Acciones
            </th>
          </tr>
        </TituloTabla>
        <tbody>
          {emails.map((item) => (
            <Item
              key={item.id}
              item={item}
              editar={editar}
              eliminar={eliminar}
              soloLectura={soloLectura}
              esadmin={esadmin}
              subasta={subasta}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListarSolicitudes;
