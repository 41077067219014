import React, { useState, useContext, useEffect } from "react";
import { Input, BotoneraForm, TextArea } from "../../layout/FormsElements";
import { Spinner, useAlerta, PersonasContext, clienteAxios } from ".";
import { BotonQuitar } from "../../layout/Estilos";

const FromOferta = ({ closeModal, emailSeleccionado, subasta }) => {
  const personaContext = useContext(PersonasContext);
  const { ocultarMensaje, mensaje } = personaContext;

  const [inicio, setInicio] = useState(true);
  const [loadingLocal, setLoadingLocal] = useState(null);

  const [file, setFile] = useState(null);

  const [setAlerta, MostrarAlerta] = useAlerta(null);

  const [soloLectura, setSoloLectura] = useState(false);

  const [DatosForm, LeerForm] = useState({
    descripcion: "",
    condicionesdescuento: "",
    id: "",
    precio1: "",
    precio2: "",
    precio3: "",
    cantidad1: subasta.cantidadperfil1,
    cantidad2: subasta.cantidadperfil2,
    cantidad3: subasta.cantidadperfil3,
    idsubasta: subasta.id,
    idestado: "",
    idetapa: subasta.idetapa,
    comentarios: "",
    imagen: "",
    nombre: "",
    stock: "",
  });

  const {
    id,
    nombre,
    descripcion,
    condicionesdescuento,
    precio1,
    precio2,
    precio3,
    cantidad1,
    cantidad2,
    cantidad3,
    comentarios,
    imagen,
    idsubasta,
    idetapa,
    stock
  } = DatosForm;

  useEffect(() => {
    const cargar = () => {
      if (emailSeleccionado) {
        LeerForm({
          stock: emailSeleccionado.stock
          ? emailSeleccionado.stock
          : "",
          idsubasta: emailSeleccionado.idsubasta
          ? emailSeleccionado.idsubasta
          : "",
          nombre: emailSeleccionado.nombre
          ? emailSeleccionado.nombre
          : "",
          descripcion: emailSeleccionado.descripcion
            ? emailSeleccionado.descripcion
            : "",
          condicionesdescuento: emailSeleccionado.condicionesdescuento
            ? emailSeleccionado.condicionesdescuento
            : "",
          idestado: emailSeleccionado.idestado
            ? emailSeleccionado.idestado
            : "",
          idetapa: emailSeleccionado.idetapa ? emailSeleccionado.idetapa : "",
          id: emailSeleccionado.id ? emailSeleccionado.id : "",
          precio1: emailSeleccionado.precio1 ? emailSeleccionado.precio1 : "",
          precio2: emailSeleccionado.precio2 ? emailSeleccionado.precio2 : "",
          precio3: emailSeleccionado.precio3 ? emailSeleccionado.precio3 : "",
          comentarios: emailSeleccionado.comentarios
            ? emailSeleccionado.comentarios
            : "",
        });
      }
    };

 /*    const deshabilitar = () => {
      if (emailSeleccionado) {
        setSoloLectura(true);
      } else {
        setSoloLectura(false);
      }
    }; */

    const alertar = () => {
      if (loadingLocal) {
        setAlerta(null);
      }
      if (inicio) {
        // eslint-disable-next-line
        ocultarMensaje();
        setInicio(null);
        setAlerta(null);
      } else {
        if (mensaje.msg && !loadingLocal) {
          setAlerta({ msg: mensaje.msg, class: mensaje.class });
        }
      }
    };

    cargar();
    alertar();
   // deshabilitar();
  }, [mensaje, inicio]);

  const Insertar = async () => {
    try {
      setLoadingLocal(true);
      const formData = new FormData();
      formData.append("imagen", imagen);
      formData.append("descripcion", descripcion);
      formData.append("condicionesdescuento", condicionesdescuento);
      formData.append("precio1", precio1);
      formData.append("precio2", precio2);
      formData.append("precio3", precio3);
      formData.append("cantidad1", cantidad1);
      formData.append("cantidad2", cantidad2);
      formData.append("cantidad3", cantidad3);
      formData.append("idsubasta", idsubasta);
      formData.append("comentarios", comentarios);
      formData.append("idetapa", idetapa);
      formData.append("nombre", nombre);
      formData.append("stock", stock);

      await clienteAxios.post("/SubastasProductos/add", formData);
      setLoadingLocal(null);
      setAlerta({ msg: "Se Creo la Oferta con Exito", class: "success" });
      //setDeshabilitado(true);
      closeModal(false);
    } catch (e) {
      setLoadingLocal(null);
      setAlerta({ msg: e.response.data.messages.msg, class: "danger" });
    }
  };

  const Actualizar = async () => {
    try {
      setLoadingLocal(true);
      const formData = new FormData();
      formData.append("id", id);
      //formData.append("imagen", imagen);
      formData.append("descripcion", descripcion);
      formData.append("condicionesdescuento", condicionesdescuento);
      formData.append("precio1", precio1);
      formData.append("precio2", precio2);
      formData.append("precio3", precio3);
      formData.append("cantidad1", cantidad1);
      formData.append("cantidad2", cantidad2);
      formData.append("cantidad3", cantidad3);
      formData.append("idsubasta", idsubasta);
      formData.append("comentarios", comentarios);
      formData.append("idetapa", idetapa);
      formData.append("nombre", nombre);
      formData.append("stock", stock);

      await clienteAxios.post("/SubastasProductos/update", formData);
      setLoadingLocal(null);
      setAlerta({ msg: "Se Actualizo la Oferta con Exito", class: "success" });
      //setDeshabilitado(true);
      closeModal(false);
    } catch (e) {
      setLoadingLocal(null);
      setAlerta({ msg: e.response.data.messages.msg, class: "danger" });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (
      descripcion.trim() === "" ||
      condicionesdescuento.trim() === "" ||
      precio1.trim() === "" ||
      precio2.trim() === "" ||
      precio3.trim() === ""
    ) {
      setAlerta({ msg: "Todos los campos es obligatorio", class: "danger" });
      return;
    }

    setAlerta(null);

    if (!emailSeleccionado) {
      Insertar();
    }else{
      Actualizar();
    }
  };

  const onChange = (e) => {
    LeerForm({
      ...DatosForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleChange = (e) => {
    if (e.target.files[0]) {
      setFile(URL.createObjectURL(e.target.files[0]));
      LeerForm({ ...DatosForm, imagen: e.target.files[0] });
    }
  };

  const borrarImagenTemp = () => {
    setFile(null);
    LeerForm({ ...DatosForm, imagen: null });
  };

  return (
    <div className="center-block">
      <h5 className="text-center">
        {emailSeleccionado ? "Ver " : "Agregar "} Oferta
      </h5>
      <form onSubmit={onSubmit} className="border p-3 form">
        <div className="row">
          <div className="col-sm">
            <Input
              key={"nombre"}
              sets={{
                label: "Nombre del Producto",
                type: "text",
                name: "nombre",
                placeholder: " ",
                valor: nombre,
                disabled: soloLectura,
              }}
              onChange={onChange}
            />
            <TextArea
              key={"descripcion"}
              sets={{
                label: "Descripcion",
                name: "descripcion",
                placeholder: "ingresar Descripcion",
                valor: descripcion,
                rows: "5",
                cols: "10",
                disabled: soloLectura,
              }}
              onChange={onChange}
            />
            <TextArea
              key={"condicionesdescuento"}
              sets={{
                label: "Condiciones de Descuento",
                name: "condicionesdescuento",
                placeholder: "ingresar condiciones de descuento",
                valor: condicionesdescuento,
                rows: "5",
                cols: "10",
                disabled: soloLectura,
              }}
              onChange={onChange}
            />
            <TextArea
              key={"comentarios"}
              sets={{
                label: "Comentarios",
                name: "comentarios",
                placeholder: "ingresar Comentario",
                valor: comentarios,
                rows: "5",
                cols: "10",
                disabled: soloLectura,
              }}
              onChange={onChange}
            />

            {/*   <Select
              key={"idrubro"}
              sets={{
                label: "Rubro ",
                name: "idrubro",
                valor: idrubro,
                disabled: soloLectura,
                opciones: rubros ? rubros : [],
              }}
              onChange={onChange}
            />
            <Input
              key={"fechaVigencia"}
              sets={{
                label: "Fecha Vigencia",
                type: "date",
                name: "fechaVigencia",
                placeholder: " ",
                valor: fechaVigencia,
                disabled: soloLectura,
              }}
              onChange={onChange}
            />
            
            <Input
              key={"valor"}
              sets={{
                label: "Precio Unitario",
                type: "number",
                name: "valor",
                placeholder: " ",
                valor: valor,
                disabled: soloLectura,
              }}
              onChange={onChange}
            /> */}
            <Input
              key={"stock"}
              sets={{
                label: "Stock",
                type: "number",
                name: "stock",
                placeholder: " ",
                valor: stock,
                disabled: soloLectura,
              }}
              onChange={onChange}
            />
          </div>
          <div className="col-sm">
            <b>Promoción 1</b>

            <Input
              key={"cantidad1"}
              sets={{
                label: "Cantidad",
                type: "number",
                name: "cantidad1",
                placeholder: " ",
                valor: cantidad1,
                disabled: true,
              }}
              onChange={onChange}
            />
            <Input
              key={"precio1"}
              sets={{
                label: "Precio Total",
                type: "number",
                name: "precio1",
                placeholder: " ",
                valor: precio1,
                disabled: soloLectura,
              }}
              onChange={onChange}
            />
            <hr></hr>
            <b>Promoción 2</b>

            <Input
              key={"cantidad2"}
              sets={{
                label: "Cantidad",
                type: "number",
                name: "cantidad2",
                placeholder: " ",
                valor: cantidad2,
                disabled: true,
              }}
              onChange={onChange}
            />
            <Input
              key={"precio2"}
              sets={{
                label: "Precio",
                type: "number",
                name: "precio2",
                placeholder: " ",
                valor: precio2,
                disabled: soloLectura,
              }}
              onChange={onChange}
            />
            <hr></hr>
            <b>Promoción 3</b>

            <Input
              key={"cantidad3"}
              sets={{
                label: "Cantidad",
                type: "number",
                name: "cantidad3",
                placeholder: " ",
                valor: cantidad3,
                disabled: true,
              }}
              onChange={onChange}
            />
            <Input
              key={"precio3"}
              sets={{
                label: "Precio",
                type: "number",
                name: "precio3",
                placeholder: " ",
                valor: precio3,
                disabled: soloLectura,
              }}
              onChange={onChange}
            />
            <input
              type="file"
              className="form-control form-control-md"
              onChange={handleChange}
              name="archivo"
              disabled={soloLectura}
            />
            <br></br>

            {file ? (
              <div className="ml-5">
                <img src={file} width="100px" alt="" />
                <div>
                  {!soloLectura ? (
                    <BotonQuitar
                      type="button"
                      onClick={() => borrarImagenTemp()}
                    >
                      <i className="fa fa-trash"></i>
                    </BotonQuitar>
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
        </div>
        {/* 
        <div className="row">
          <hr></hr>
          <div className="col-sm">
            <b>Resultado Evaluacion</b>
            <br></br>
            <br></br>
            <Select
              key={"idestado"}
              sets={{
                disabled: usuario.esadmin === "0" ? true : false,
                label: "Estado ",
                name: "idestado",
                valor: idestado,
                opciones: [
                  { value: "1", label: "En Evaluacion" },
                  { value: "2", label: "Aprobado" },
                  { value: "3", label: "Rechazado" },
                ],
              }}
              onChange={onChange}
            />
          </div>
          <div className="col-sm">
            <TextArea
              key={"comentarios"}
              sets={{
                disabled: usuario.esadmin === "0" ? true : false,
                label: "Comentarios",
                name: "comentarios",
                placeholder: "ingresar Comentarios",
                valor: comentarios,
                rows: "5",
                cols: "10",
              }}
              onChange={onChange}
            />
          </div>
        </div>
         */}
        {loadingLocal ? <Spinner /> : <MostrarAlerta />}

        <BotoneraForm
          funcionCancelar={closeModal}
          valorfuncion={true}
          deshabilitado={soloLectura}
        />
      </form>
    </div>
  );
};
export default FromOferta;
