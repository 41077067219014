import React from "react";
import Proveedor from "./Proveedor";

const Proveedores = ({ proveedores, SetProveedorSeleccionado, idsubasta }) => {
  const mostrar = (item) => {
    SetProveedorSeleccionado(item);
  };

  return (
    <>
      {proveedores ? (
        <div className="p-2 row justify-content-center ">
          <h2 className="text-center">
            Proveedores participantes de la Subasta:
          </h2>
          <br></br> <br></br>
          <br></br>
          {proveedores.map((item) => (
            <Proveedor
              key={item.id}
              item={item}
              mostrar={mostrar}
              idsubasta={idsubasta}
            />
          ))}
        </div>
      ) : null}
    </>
  );
};

export default Proveedores;
