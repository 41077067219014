import React, { useState, useContext, useEffect } from "react";
import { clienteAxios, AuthContext } from "../";
import ListarProveedores from "./proveedores/ListarProveedores";
import ListarCatalogo from "./ListarCatalogo";

const Catalogo = () => {
  const authContext = useContext(AuthContext);
  const { usuario } = authContext;

  const [articulos, setArticulos] = useState(null);

  const [proveedores, SetProveedores] = useState(null);
  const [proveedorSeleccionado, SetProveedorSeleccionado] = useState(null);
  const [consultar, SetConsultar] = useState(null);

  useEffect(() => {
    const cargarProveedores = async () => {
      try {
        if (!proveedores) {
          const resultp = await clienteAxios.get("/Subastas/getProveedores", {
            params: {
              idsubasta: "0",
            },
          });
          //console.log(resultp.data);
          SetProveedores(resultp.data.proveedores);
        }
      } catch (e) {}
    };

    cargarProveedores();
  }, [proveedores]);

  useEffect(() => {
    const cargarArticulos = async () => {
      if (!consultar) return;

      try {
        if (!articulos) {
          const resultp = await clienteAxios.get(
            "/Subastas/getArticulosProveedor",
            {
              params: {
                idsubasta: "0",
                idproveedor: proveedorSeleccionado.id,
              },
            }
          );
         // console.log('cc',resultp.data.articulos);
          setArticulos(resultp.data.articulos);
        }
      } catch (e) {}
      SetConsultar(null);
    };

    cargarArticulos();
  }, [articulos, proveedorSeleccionado, consultar]);

  return (
    <div>
      <h2 className="text-center">Catalogo de Ofertas</h2>
      {proveedorSeleccionado === null ? (
        <ListarProveedores
          proveedores={proveedores}
          SetProveedorSeleccionado={SetProveedorSeleccionado}
          SetConsultar={SetConsultar}
          setArticulos={setArticulos}
        />
      ) : (
        <ListarCatalogo
          catalogo={articulos}
          proveedorSeleccionado={proveedorSeleccionado}
          SetProveedorSeleccionado={SetProveedorSeleccionado}
          idproveedor={proveedorSeleccionado.id}
        />
      )}
    </div>
  );
};

export default Catalogo;
