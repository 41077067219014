import React from "react";
import styled from "@emotion/styled";

//xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

const ImgPC = styled.div`
@media only screen and (max-width: 600px) {
  display: none
  }
`;
const ImgCel = styled.div`
@media only screen and (min-width: 600px) {
  display: none
  }
`;
const Main = () => {
  return (
    <>
      <ImgPC className="text-center">
        <img src="/assets/images/bannerweb.jpg" alt="pool compras"/>
      </ImgPC>
      <ImgCel className="text-center">
        <img src="/assets/images/Logo-Pool-Color.png" alt="pool compras" width="200px"/><br></br>
        Pool de Compras Para el Sector Metalurgico
      </ImgCel>
    </>
  );
};

export default Main;
