import React, { useState, useEffect } from "react";
import Listar from "./ListarNotificaciones";
import { Spinner, clienteAxios, Portal } from ".";
import { BotonAgregar } from "../layout/Estilos";
import Form from "./FromNotificacion";
import { Input } from "../layout/FormsElements";

const Notificaciones = ({ id, soloLectura }) => {
 
  const [loadingLocal, setLoadingLocal] = useState(null);
  const [emailsEnviados, setEmailsEnviados] = useState([]);
  const [emailsRecibidos, setEmailsRecibidos] = useState([]);

  const [consultar, setConsultar] = useState(true);
  const [emailSeleccionado, setemailSeleccionado] = useState(null);
  const [DatosForm, LeerForm] = useState({ buscar: "" });
  const { buscar } = DatosForm;
  //modal
  const [isOpened, setOpened] = useState(false);
  const openModal = () => {
    setOpened(true);
    window.scrollTo(0, 0);
  };
  const closeModal = (cierro) => {
    if (cierro) {
      setOpened(false);
    }
    setConsultar(true);
    setemailSeleccionado(null);
  };
  //modal

  useEffect(() => {
    const cargar = async () => {
      if (!consultar) return;

      try {
        setLoadingLocal(true);

        const result = await clienteAxios.get("/Notificaciones/getAll", {
          params: { texto: buscar },
        });
        setEmailsEnviados(result.data.enviados);
        setEmailsRecibidos(result.data.recibidos);

        setLoadingLocal(null);
      } catch (e) {}
      setConsultar(null);
    };

    cargar();
  }, [consultar, emailSeleccionado, id, buscar]);

 

  const onChange = (e) => {
    LeerForm({
      ...DatosForm,
      [e.target.name]: e.target.value,
    });

    if (buscar.length > 1) {
      setConsultar(true);
    }
  };

  return (
    <div className="center-block">
      <br></br>
      <h2>Gestion de Notificaciones</h2>
      <div className="row">
        {!soloLectura ? (
          <div className="col-sm">
            <div className="form-group">
              {!isOpened ? (
                <BotonAgregar
                  onClick={() => {
                    openModal();
                  }}
                  className="btn btn-dark"
                >
                  <i className="fa fa-plus-circle"></i> Crear Notificacion
                </BotonAgregar>
              ) : null}
            </div>
          </div>
        ) : null}
        <div className="col-sm">
          <Input
            key={"buscar"}
            sets={{
              label: "",
              type: "text",
              name: "buscar",
              placeholder: "Ingrese su Busqueda",
              valor: buscar,
            }}
            onChange={onChange}
          />
        </div>

        <br></br>
      </div>

      <br></br>
      {loadingLocal || !emailsRecibidos ? (
        <Spinner />
      ) : (
        <>
        <b>Recibidos:</b>
        <Listar
          emails={emailsRecibidos}
          setemailSeleccionado={setemailSeleccionado}
          setConsultar={setConsultar}
          openModal={openModal}
          soloLectura={soloLectura}
        />
        </>
      )}

<br></br>
      {loadingLocal || !emailsEnviados ? (
        <Spinner />
      ) : (
        <>
        <b>Enviados:</b>
        <Listar
          emails={emailsEnviados}
          setemailSeleccionado={setemailSeleccionado}
          setConsultar={setConsultar}
          openModal={openModal}
          soloLectura={soloLectura}
        />
        </>
      )}

      <Portal isOpened={isOpened} onClose={closeModal}>
        <Form
          id={id}
          closeModal={closeModal}
          emailSeleccionado={emailSeleccionado}
        />
      </Portal>
    </div>
  );
};

export default Notificaciones;
