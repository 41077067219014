import React, { useEffect, useState } from "react";
import OfertasUsuario from "./OfertasUsuario";
import { clienteAxios } from "./";

const OfertarProveedor = (props) => {
  const [subasta, setSubasta] = useState(null);

  useEffect(() => {
    const cargarCombos = async () => {
      try {
        if (!subasta) {
          const resultp = await clienteAxios.get("/Subastas/get", {
            params: {
              id: props.match.params.idsubasta,
              idsolicitud: "0",
              idproducto: "0",
            },
          });

          setSubasta(resultp.data.subasta);
        }
      } catch (e) {}
    };

    cargarCombos();
  }, [props.match.params.idsubasta, subasta]);

  if (subasta) {
    return <OfertasUsuario subasta={subasta[0]} />;
  } else {
    return <></>;
  }
};

export default OfertarProveedor;
