import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext, clienteAxios } from "./";
import DasboardAdmin from "../perfiles/admin/DasboardAdmin";
import DasboardUsuario from "../perfiles/usuario/DasboardUsuario";

const Dashboard = () => {
  const authContext = useContext(AuthContext);
  const { usuario } = authContext;

  //info usuarios
  const [infousuario, setInfoUsuario] = useState({
    usuarios: {},
    comprador: [],
    vendedor: [],
    Subastas: {SubastaP: [], SubastaC: []},
  });

  useEffect(() => {
    const cargarinfousuario = async () => {
      try {
          const resultp = await clienteAxios.get("/User/get");
          setInfoUsuario(resultp.data);
          //console.log(resultp.data);
      } catch (e) {}
    };
    cargarinfousuario();
  }, []);
  //info usuarios

  
  return (
    <div style={{ padding: "10px", margin: "auto" }}>
      <br></br>

      <h2 className="text-center">Hola {usuario.nombre}!</h2>
      <br></br>

      {usuario.esadmin === "0" ? <DasboardUsuario infousuario={infousuario}/> : <DasboardAdmin />}

      <div className="justify-content-center text-center">
        <br></br>
        <Link aria-label="Cambiar Contraseña" to="/cambiarpassword">
          Cambiar Contraseña
        </Link>
      </div>
    </div>
  );
};

export default Dashboard;
