import React from "react";
import ItemCatalogo from "./ItemCatalogo";
import { Link } from "react-router-dom";

const ListarCatalogo = ({
  catalogo,
  proveedorSeleccionado,
  idsubasta,
  SetProveedorSeleccionado,
}) => {

  const elegirOtro = () => {
    SetProveedorSeleccionado(null);
  };

  return (
    <>
      <h2 className="text-center">
        Articulos de {proveedorSeleccionado.razonsocial}:
      </h2>
      <br></br> <br></br>
      <div className="text-center">
        <Link to={"/catalogo/" + idsubasta} onClick={() => elegirOtro()}>
          Elegir Otro Proveedor
        </Link>
      </div>
      <br></br>
      <div className="p-2 row justify-content-center ">
        {catalogo ? (
          <>
            {catalogo.map((item) => (
              <ItemCatalogo key={item.id} item={item} idsubasta={idsubasta}/>
            ))}
          </>
        ) : null}
      </div>
      <div className="text-center">
        <br></br>
        <hr></hr>
        <br></br>
        <Link to={"/catalogo/" + idsubasta} onClick={() => elegirOtro()}>
          Elegir Otro Proveedor
        </Link>
        <p></p>

      </div>
    </>
  );
};

export default ListarCatalogo;
