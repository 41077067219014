import styled from "@emotion/styled";

export const BodyTabla = styled.tbody`
  background-color:"white"
`;

export const TituloTabla = styled.thead`
  background-color: #f9f9f9 !important;
  font-size: 13px !important;
  font-weight: normal !important;
  color: #5e5e5e;
`;

export const BotonTransparente = styled.button`
  background-color: #f9f9f9 !important;
  font-size: 16px !important;
  font-weight: bold !important;
  color: #5e5e5e;
  border: none;
  outline: none;
  text-decoration: none;
  display: inline-block;
`;


export const LiSinPunto = styled.li`
list-style:none;
`;

export const BotonCancelar = styled.button`
background-color: #c12d37;
border-color: #c12d37;
color: white;
border-radius: 15px;
:hover {
  background-color: lightgray;
  color: white !important;
}
`;

export const BotonAmarillo = styled.button`
background-color: orange;
color: white !important;
border-color: orange;
margin-right: 5px;
font-size: 12px;
border-radius: 8px;
:hover {
  background-color: #c54436;
}
`;

export const BotonVerde = styled.button`
background-color: green;
color: white !important;
border-color: #91c854;
margin-right: 5px;
font-size: 12px;
border-radius: 8px;
:hover {
  background-color: #c54436;
}
`;

export const BotonRojo = styled.button`
  background-color: red;
  color: white !important;
  border-color: #d42034;
  margin-right: 5px;
  font-size: 12px;
  border-radius: 8px;
  :hover {
    background-color: #c54436;
  }
`;

export const Boton = styled.button`
  background-color: #93ca53;
  color: white !important;
  border-color: #93ca53;
  :hover {
    background-color: darkgray;
    color: white !important;
  }
`;

export const BotonLogin = styled.button`
  background-color: #c12b35;
  color: white !important;
  border-color: red;
  border-radius: 15px;
  :hover {
    background-color: darkred;
    color: white !important;
  }
`;

export const BotonEditar = styled.button`
  background-color: #FE2E64;
  color: white !important;
  border-radius: 10px;
  :hover {
    background-color: darkred;
    color: white !important;
  }
`;

export const BotonAgregar = styled.button`
  background-color: #1a73e8;
  color: white !important;
  border-color: #1a73e8;
  border-radius: 15px;
  :hover {
    background-color: darkgray;
    color: white !important;
  }
`;

export const BotonRedondo = styled.button`
  background-color: #002653;
  color: white;
  border-color: #002653;
  border-radius: 20px;

  :hover {
    background-color: #c54436;
  }
`;


export const BotonAceptar = styled.button`
  background-color: #f5f5f5;
  color: white;
  border-color: #002653;

  :hover {
    background-color: darkgray;
  }
`;

export const BotonQuitar = styled.button`
  border: none;
  background-color: lightgray;
  color: red;
  border-radius: 20px;
  margin: 5px;
`;