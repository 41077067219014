import React from "react";
import Item from "./Producto";
import { TituloTabla } from "../layout/Estilos";
import { clienteAxios, Swal, useHistory } from ".";

const ListarProductos = ({
  emails,
  setemailSeleccionado,
  setConsultar,
  openModal,
  soloLectura,
  esadmin,
  interesados
}) => {
  const history = useHistory();

  const borrar = async (id) => {
    try {
      await clienteAxios.delete("/Productos/remove", {
        params: { id },
      });
      setConsultar(true);
    } catch (e) {}
  };

  const editar = (email) => {
    setemailSeleccionado(email);
    openModal();
  };

  const eliminar = (email) => {
    Swal.fire({
      title: "Esta Seguro que desea Eliminar?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        borrar(email.id);
      }
    });
  };

  const subasta = (email) => {
    localStorage.removeItem('idproducto');
    localStorage.removeItem('nombre');
    localStorage.removeItem('idrubro');
    localStorage.removeItem('idsolicitud');
    localStorage.removeItem('idsubasta');

    localStorage.setItem('idproducto', email.id);
    localStorage.setItem('nombre', email.nombre);
    localStorage.setItem('idrubro', email.idrubro);

    history.push('/admin/gestionsubastas');
  };

  return (
    <div className="table-responsive">
      <table
        className="table table-striped"
        style={{ backgroundColor: "white" }}
      >
        <TituloTabla>
          <tr>
          <th scope="col" className="text-center">
              Fecha
            </th>
            <th scope="col" className="text-center">
              Oferta
            </th>
            <th scope="col" className="text-center">
              Precio Unitario
            </th>
            {esadmin==="1" ? <th scope="col" className="text-center">
              Proveedor
            </th>: null }
            {esadmin==="1" ? <th scope="col" className="text-center">
              Interesados
            </th>: null }
            <th scope="col" className="text-center">
              Estado
            </th>
            <th scope="col" className="text-center">
              Acciones
            </th>
          </tr>
        </TituloTabla>
        <tbody>
          {emails.productos.map((item) => (
            <Item
              key={item.id}
              item={item}
              precios={emails.precios.filter(
                (precio) => precio.Idproducto === item.id
              )}
              editar={editar}
              eliminar={eliminar}
              soloLectura={soloLectura}
              esadmin={esadmin}
              subasta={subasta}
              interesados={interesados.filter((interes)=> interes.Idproducto = item.id )}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListarProductos;
