import UsuariosRubros from "../../../usuariorubros/UsuariosRubros"

const Vendedor = () => {
    return (
        <div className="p-2 row justify-content-center ">
        <h2>Configuración para Proveedor</h2>
      <div className="card" style={{ width: "90%", margin: "10px" }}>
        <div className="card-body">
          <h5 className="card-title">Rubros</h5>
          <p className="card-text">
            <UsuariosRubros tipo={"2"} soloLectura={false} />
          </p>
        </div>
      </div>
    </div>
    );
};

export default Vendedor;