import React, { useState, useContext, useEffect, useCallback } from "react";
import { Boton } from "../layout/Estilos";
import { AuthContext, useAlerta, useRest, Spinner, clienteAxios } from "./";
import styled from "@emotion/styled";
import { BotonQuitar } from "../layout/Estilos";
import _ from "lodash";

const ContenedorLogin = styled.div`
  @media only screen and (max-width: 600px) {
    width: 100%;
    padding: 10px;
    min-height: 600px;
  }

  @media (min-width: 600px) {
    width: 90%;
    padding: 20px;
    min-height: 600px;
  }
`;

const NuevaCuenta = () => {
  const authContext = useContext(AuthContext);
  const { registrarUsuario, ocultarMensaje, mensaje } = authContext;

  const [setAlerta, MostrarAlerta] = useAlerta(null);
  const [loading, load] = useRest();
  const [loadingLocal, setLoadingLocal] = useState(null);


  const [DatosForm, LeerForm] = useState({
    nickname: "",
    razonsocial: "",
    nombre: "",
    apellido: "",
    dni: "",
    telefono: "",
    email: "",
    cuit: "",
    direccion: "",
    localidad: "",
    password: "",
    confirmar: "",
    url: "",
    formacontacto: "",
    sociocimcc: "",
    actividadprimaria: "",
    actividadsecundaria: "",
    cdadempleados: "",
    cp_mandosgerenciales: "",
    cp_mandosmedios: "",
    cp_operarios: "",
    codigoafip: "",
    descripcionafip: "",
    logo: "",
  });

  const {
    nickname,
    razonsocial,
    nombre,
    apellido,
    dni,
    direccion,
    localidad,
    cuit,
    telefono,
    email,
    password,
    confirmar,
    url,
    formacontacto,
    sociocimcc,
    actividadprimaria,
    actividadsecundaria,
    cdadempleados,
    cp_mandosgerenciales,
    cp_mandosmedios,
    cp_operarios,
    codigoafip,
    descripcionafip,
    logo,
  } = DatosForm;

  const [inicio, setInicio] = useState(true);
  const [file, setFile] = useState(null);

  const delayedQuery = useCallback(
    _.debounce((id, info) => descripcionAfip(id, info), 500),
    []
  );

  const descripcionAfip = async (id, info) => {
    let descripcion = "";
    try {
      const result = await clienteAxios.get("/CodigosAfip/get", {
        params: { id },
      });

      if (result.data.afip) {
        descripcion = result.data.afip.DESCL_ACTIVIDA_F883;
      }
    } catch (e) {
      //console.log(e);
    }

    LeerForm({
      ...info,
      descripcionafip: descripcion,
      codigoafip: id,
    });
  };

  useEffect(() => {
    const alertar = () => {
      if (loadingLocal) {
        setAlerta(null);
      }
      if (inicio) {
        // eslint-disable-next-line
        ocultarMensaje();
        setInicio(null);
        setAlerta(null);
      } else {
        if (mensaje.msg && !loadingLocal) {
          setAlerta({ msg: mensaje.msg, class: mensaje.class });
        }
      }
    };

    alertar();
  }, [mensaje, inicio]);

  const onChange = (e) => {
    if (e.target.name === "codigoafip") {
      delayedQuery(e.target.value, DatosForm);
    }
    //console.log(DatosForm);

    LeerForm({
      ...DatosForm,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (
      codigoafip.trim() === "" ||
      razonsocial.trim() === "" ||
      nombre.trim() === "" ||
      apellido.trim() === "" ||
      dni.trim() === "" ||
      direccion.trim() === "" ||
      localidad.trim() === "" ||
      email.trim() === "" ||
      password.trim() === "" ||
      confirmar.trim() === "" ||
      cuit.trim() === "" ||
      telefono.trim() === "" ||
      descripcionafip.trim === ""
    ) {
      setAlerta({ msg: "Todos los campos son obligatorios", class: "danger" });
      return;
    }
    if (cuit.trim().length < 11 || cuit.trim().length > 11) {
      setAlerta({ msg: "CUIL debe tener 11 caracteres", class: "danger" });
      return;
    }
    if (dni.trim().length < 8 || dni.trim().length > 8) {
      setAlerta({
        msg: "Nro de Documento debe tener 8 caracteres",
        class: "danger",
      });
      return;
    }
    if (password.trim().length < 8) {
      setAlerta({
        msg: "Password debe tener minimo 8 caracteres",
        class: "danger",
      });
      return;
    }
    if (password.trim() !== confirmar.trim()) {
      setAlerta({ msg: "Los Passwords no coinciden", class: "danger" });
      return;
    }
    setAlerta(null);

    setLoadingLocal(true);
    const formData = new FormData();
    formData.append("nickname", nickname);
    formData.append("razonsocial", razonsocial);
    formData.append("nombre", nombre);
    formData.append("apellido", apellido);
    formData.append("dni", dni);
    formData.append("direccion", direccion);
    formData.append("localidad", localidad);
    formData.append("cuit", cuit);
    formData.append("telefono", telefono);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("url", url);
    formData.append("formacontacto", formacontacto);
    formData.append("sociocimcc", sociocimcc);
    formData.append("actividadprimaria", actividadprimaria);
    formData.append("actividadsecundaria", actividadsecundaria);
    formData.append("cdadempleados", cdadempleados);
    formData.append("cp_mandosgerenciales", cp_mandosgerenciales);
    formData.append("cp_mandosmedios", cp_mandosmedios);
    formData.append("cp_operarios", cp_operarios);
    formData.append("codigoafip", codigoafip);
    formData.append("descripcionafip", descripcionafip);
    formData.append("logo", logo);
    await clienteAxios.post("/users", formData);
    registrarUsuario();
    
    setLoadingLocal(null);
    LeerForm({
      nickname: "",
      razonsocial: "",
      nombre: "",
      apellido: "",
      dni: "",
      telefono: "",
      email: "",
      cuit: "",
      direccion: "",
      localidad: "",
      password: "",
      confirmar: "",
      url: "",
      formacontacto: "",
      sociocimcc: "",
      actividadprimaria: "",
      actividadsecundaria: "",
      cdadempleados: "",
      cp_mandosgerenciales: "",
      cp_mandosmedios: "",
      cp_operarios: "",
      codigoafip: "",
      descripcionafip: "",
      logo: "",
    })
    setFile(null);
    setLoadingLocal(null);
    setAlerta({ msg: "Se Registro con Exito", class: "success" });
  };

  const handleChange = (e) => {
    if (e.target.files[0]) {
      setFile(URL.createObjectURL(e.target.files[0]));
      LeerForm({ ...DatosForm, logo: e.target.files[0] });
    }
  };

  const borrarImagenTemp = () => {
    setFile(null);
    LeerForm({ ...DatosForm, logo: null });
  };

  return (
    <ContenedorLogin>
      <br></br>
      <h2 className="text-center">Registrarse en Pool de Compras</h2>
      <br></br>

      <br></br>
      <div className="row">
        <div className="col-sm text-center">
          <div className="text-center">
            Complete los siguientes datos para Registrarse. Una vez completa la
            información solicitada, presione el boton "Registrarme".
            <br></br>
            Luego de realizar lo mencionado se enviara un email a la casilla
            detallada. Debera ingresar al email enviado y presionar "Activar"
            (sino encuentra el email, verifique en la carpeta Spam). Esta acción
            activara su cuenta para operar en este sitio. Recuerde que para
            poder Ingresar debe ser autorizado por la Adminstracion del sitio.
            <br></br>
          </div>
          <br></br>
          <img src="./assets/images/logoAdec.png" width="30%" alt="registro" />
          <br></br>
          <img
            src="./assets/images/logo-anclaje-vertical.png"
            width="50%"
            alt="registro"
          />
          <br></br>
        </div>

        <div className="col-sm">
          <form onSubmit={onSubmit}>
            <div className="form-group">
              <label htmlFor="razonsocial">Razon Social</label>
              <input
                type="text"
                name="razonsocial"
                className="form-control"
                id="razonsocial"
                aria-describedby="emailHelp"
                placeholder="Ingrese Razon Social"
                onChange={onChange}
                value={razonsocial}
              />
            </div>
            {/*  <div className="form-group mt-3">
              <label htmlFor="nickname">Usuario</label>
              <input
                type="text"
                name="nickname"
                className="form-control"
                id="nickname"
                aria-describedby="emailHelp"
                placeholder="Ingrese Usuario"
                onChange={onChange}
                value={nickname}
              />
            </div> */}
            <div className="form-group mt-3">
              <label htmlFor="dni">Nro Documento Contacto</label>
              <input
                type="number"
                name="dni"
                className="form-control"
                id="dni"
                aria-describedby="emailHelp"
                placeholder="Ingrese su nro. de documento"
                onChange={onChange}
                value={dni}
              />
            </div>
            <div className="form-group mt-3">
              <label htmlFor="nombre">Nombre Contacto</label>
              <input
                type="text"
                name="nombre"
                className="form-control"
                id="nombre"
                aria-describedby="emailHelp"
                placeholder="Ingrese su Nombre"
                onChange={onChange}
                value={nombre}
              />
            </div>
            <div className="form-group mt-3">
              <label htmlFor="apellido">Apellido Contacto</label>
              <input
                type="text"
                name="apellido"
                className="form-control"
                id="apellido"
                aria-describedby="emailHelp"
                placeholder="Ingrese su Apellido"
                onChange={onChange}
                value={apellido}
              />
            </div>
            <div className="form-group mt-3">
              <label htmlFor="cuit">CUIT</label>
              <input
                type="number"
                name="cuit"
                className="form-control"
                id="cuit"
                aria-describedby="emailHelp"
                placeholder="Ingrese su CUIT (sin guiones)"
                onChange={onChange}
                value={cuit}
              />
            </div>
            <div className="form-group mt-3">
              <label htmlFor="direccion">Direccion</label>
              <input
                type="text"
                name="direccion"
                className="form-control"
                id="direccion"
                aria-describedby="emailHelp"
                placeholder="Ingrese su Direccion"
                onChange={onChange}
                value={direccion}
              />
            </div>
            <div className="form-group mt-3">
              <label htmlFor="localidad">Localidad</label>
              <input
                type="text"
                name="localidad"
                className="form-control"
                id="localidad"
                aria-describedby="emailHelp"
                placeholder="Ingrese su Localidad"
                onChange={onChange}
                value={localidad}
              />
            </div>
            <div className="form-group mt-3">
              <label htmlFor="telefono">Telefono</label>
              <input
                type="text"
                name="telefono"
                className="form-control"
                id="telefono"
                aria-describedby="emailHelp"
                placeholder="Ingrese su Telefono"
                onChange={onChange}
                value={telefono}
              />
            </div>
            <div className="form-group mt-3">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                className="form-control"
                id="email"
                aria-describedby="emailHelp"
                placeholder="Ingrese su Email"
                onChange={onChange}
                value={email}
              />
            </div>
            <div className="form-group mt-3">
              <label htmlFor="formacontacto">Forma de Contacto</label>
              <select
                className="form-select"
                id="formacontacto"
                name="formacontacto"
                onChange={onChange}
                value={formacontacto}
              >
                <option>Seleccione...</option>

                <option value="1">Telefonica</option>
                <option value="2">Email</option>
                <option value="3">Email y Telefono</option>
              </select>
            </div>
            <div className="form-group mt-3">
              <label htmlFor="sociocimcc">Socio CIMCC</label>
              <select
                className="form-select"
                id="sociocimcc"
                name="sociocimcc"
                onChange={onChange}
                value={sociocimcc}
              >
                <option>Seleccione...</option>
                <option value="1">Si</option>
                <option value="0">No</option>
              </select>
            </div>
            <div className="form-group mt-3">
              <label htmlFor="password">Contraseña</label>
              <input
                type="password"
                name="password"
                className="form-control"
                id="password"
                aria-describedby="emailHelp"
                placeholder="Ingrese su Contraseña"
                onChange={onChange}
                value={password}
              />
            </div>
            <div className="form-group mt-3">
              <label htmlFor="confirmar">Confirmar Contraseña</label>
              <input
                type="password"
                name="confirmar"
                className="form-control"
                id="confirmar"
                aria-describedby="emailHelp"
                placeholder="Ingrese su Contraseña de Nuevo"
                onChange={onChange}
                value={confirmar}
              />
            </div>
            <div className="form-group mt-3">
              <label htmlFor="codigoafip">Codigo Actividad Afip</label>
              <input
                type="number"
                name="codigoafip"
                className="form-control"
                id="codigoafip"
                placeholder="Ingrese su Codigo Actividad Afip"
                onChange={onChange}
                value={codigoafip}
              ></input>
            </div>
            <div className="form-group mt-3">
              <textarea
                type="text"
                disabled={true}
                name="descripcionafip"
                className="form-control"
                id="descripcionafip"
                placeholder="Ingrese su Codigo Actividad Afip"
                onChange={onChange}
                value={descripcionafip}
                rows="3"
              ></textarea>
            </div>
            {/*             <div className="form-group mt-3">
              <label htmlFor="actividadprimaria">Actividad Primaria</label>
              <textarea
                rows="5"
                cols="10"
                name="actividadprimaria"
                className="form-control"
                id="actividadprimaria"
                placeholder="Ingrese su Actividad Primaria"
                onChange={onChange}
                value={actividadprimaria}
                
              ></textarea>
              
            </div>
            <div className="form-group mt-3">
              <label htmlFor="actividadsecundaria">Actividad Secundaria</label>
              <textarea
                rows="5"
                cols="10"
                name="actividadsecundaria"
                className="form-control"
                id="actividadsecundaria"
                placeholder="Ingrese su Actividad Secundaria"
                onChange={onChange}
                value={actividadsecundaria}
                
              ></textarea>
              
            </div>

            <div className="form-group mt-3">
              <label htmlFor="cuit">Cdad. de Empleados
              </label>
              <input
                type="number"
                name="cdadempleados"
                className="form-control"
                id="cdadempleados"
                aria-describedby="emailHelp"
                placeholder="Ingrese su Cdad. de Empleados"
                onChange={onChange}
                value={cdadempleados}
              />
            </div>
            <br></br>
            <strong className="mt-3">Capacidad Laboral:</strong>
            <div className="form-group mt-3">
              <label htmlFor="cp_mandosgerenciales">% Mandos Gerenciales
              </label>
              <input
                type="number"
                name="cp_mandosgerenciales"
                className="form-control"
                id="cp_mandosgerenciales"
                aria-describedby="emailHelp"
                placeholder="Ingrese % mandos gerenciales"
                onChange={onChange}
                value={cp_mandosgerenciales}
              />
            </div>
            <div className="form-group mt-3">
              <label htmlFor="cp_mandosmedios">% Mandos Medios
              </label>
              <input
                type="number"
                name="cp_mandosmedios"
                className="form-control"
                id="cp_mandosmedios"
                aria-describedby="emailHelp"
                placeholder="Ingrese % mandos medios"
                onChange={onChange}
                value={cp_mandosmedios}
              />
            </div>
            <div className="form-group mt-3">
              <label htmlFor="cp_operarios">% Operarios
              </label>
              <input
                type="number"
                name="cp_operarios"
                className="form-control"
                id="cp_operarios"
                aria-describedby="emailHelp"
                placeholder="Ingrese % operarios"
                onChange={onChange}
                value={cp_operarios}
              />
            </div>

 */}{" "}
            <br></br>
            Seleccione Logo:
            <br></br>
            <input
              type="file"
              className="form-control form-control-md"
              onChange={handleChange}
              name="archivo"
              disabled={false}
            />
            <br></br>
            {file ? (
              <div className="ml-5">
                <img src={file} width="100px" alt="" />
                <div>
                  <BotonQuitar type="button" onClick={() => borrarImagenTemp()}>
                    <i className="fa fa-trash"></i>
                  </BotonQuitar>
                </div>
              </div>
            ) : null}
            <br></br>
            {loadingLocal ? <Spinner /> : <MostrarAlerta />}

            <div className="form-group mt-3">
              <br></br>
              <Boton
                style={{ width: "100%" }}
                type="submit"
                className=" btn btn-block btn-lg btn-primary"
              >
                Registrarme
              </Boton>
            </div>
          </form>

          <br></br>
          <br></br>
        </div>
      </div>
    </ContenedorLogin>
  );
};

export default NuevaCuenta;
