import React from "react";
import { BotonEditar, BotonEliminar } from "../layout/FormsElements";

const UsuarioRubro = ({ item, editar, eliminar, tipo }) => {
  return (
    <tr>
      <td className="text-center">{item.rubro}</td>
      {tipo === "1" ? (
        <td className="text-center">{item.activo === "0" ? "No" : "Si"}</td>
      ) : null}
      {tipo === "1" ? (
        <td className="text-center">
          <BotonEditar editar={editar} item={item} />
          <BotonEliminar eliminar={eliminar} item={item} />
        </td>
      ) : null}
    </tr>
  );
};

export default UsuarioRubro;
