import { Link } from "react-router-dom";

const DasboardUsuario = ({ infousuario }) => {
  return (
    <>
      <div className="p-2 row justify-content-center ">
        <div
          className="card"
          style={{ width: "30rem", margin: "10px", backgroundColor: "#FBEFFB" }}
        >
          <div className="card-body">
            <h5 className="card-title">Comprador</h5>
            <p className="card-text">
              Aquí podrá configurar y gestionar para operar como Empresa
              Compradora. Además, podrá solicitar ofertas y otras acciones como
              Demandante.
            </p>
            {infousuario.comprador.length > 0 ? (
              <div className="alert alert-success" role="alert">
                <span>Usted Ya se configuró como Comprador</span>
              </div>
            ) : (
              <div className="alert alert-info" role="alert">
                <span>Usted NO no se ha configurado como Comprador</span>
              </div>
            )}
            <Link to="usuario/comprador" className="btn btn-primary">
              Rubros
            </Link>{" "}
            {infousuario.comprador.length > 0 ? (
              <Link to="usuario/solicitudes" className="btn btn-primary">
                Solicitar Ofertas
              </Link>
            ) : null}{" "}
            {infousuario.Subastas ? (
              <>
                {infousuario.Subastas.SubastaC.length > 0 ? (
                  <Link
                    to={`catalogo/${infousuario.Subastas.SubastaC[0].id}`}
                    className="btn btn-primary"
                  >
                    Catálogo de Subasta
                  </Link>
                ) : null}
              </>
            ) : null}
          </div>
        </div>
        <div
          className="card"
          style={{ width: "30rem", margin: "10px", backgroundColor: "#FBF5EF" }}
        >
          <div className="card-body">
            <h5 className="card-title">Proveedor</h5>
            <p className="card-text">
              Ingrese aquí para darse de alta y gestionar como Proveedor.{" "}
            </p>
            <br></br>
            {infousuario.vendedor.length > 0 ? (
              <div className="alert alert-success" role="alert">
                <span>Usted Ya se configuró como Proveedor</span>
              </div>
            ) : (
              <div className="alert alert-info" role="alert">
                <span>Usted no se ha configurado como Proveedor</span>
              </div>
            )}
            <Link to="usuario/vendedor" className="btn btn-primary">
              Rubros
            </Link>{" "}
            {infousuario.vendedor.length > 0 ? (
              <>
                <Link to="usuario/productos" className="btn btn-primary">
                  Mis Ofertas
                </Link>{" "}
              </>
            ) : null}
            {infousuario.Subastas ? (
              <>
                {infousuario.Subastas.SubastaP.length > 0 ? (
                  <Link to={`/missubastas`} className="btn btn-primary">
                    Oferte en Subasta
                  </Link>
                ) : null}{" "}
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default DasboardUsuario;
