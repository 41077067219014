import React from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

const Proveedor = ({ item, mostrar, idsubasta }) => {
 
  const DivCard = styled.div`
    margin-right: 10px;
    width: 250px;
    margin-bottom: 10px;
  `;

  const Divimagen = styled.div`
    background-image: url(https://pooldecompras.cimcc.org.ar/Api/writable/uploads/${item.logo});
    width: 226px;
    height: 150px;
    background-image: no-repeat;
    background-image: fixed;
    background-image: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  `;

  return (
    <DivCard className="card">
      <Link
        to={`/catalogo/${idsubasta}`}
        onClick={() => {
          mostrar(item);
        }}
      >
        <Divimagen></Divimagen>
      </Link>
      <div className="card-body">
        <h6 className="card-title text-center" >{item.razonsocial}</h6>
      </div>
    </DivCard>
  );
};

export default Proveedor;
