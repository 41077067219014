import React, { useState, useEffect } from "react";
import Listar from "./ListarRubros";
import { Spinner, clienteAxios, Portal } from ".";
import { BotonAgregar } from "../layout/Estilos";
import Form from "./FromRubro";
import {Input} from "../layout/FormsElements";


const Rubros = ({ id, soloLectura }) => {
  const [loadingLocal, setLoadingLocal] = useState(null);
  const [emails, setEmails] = useState(null);

  const [consultar, setConsultar] = useState(true);
  const [emailSeleccionado, setemailSeleccionado] = useState(null);

  //modal
  const [isOpened, setOpened] = useState(false);
  const openModal = () => {
    setOpened(true);
    window.scrollTo(0, 0);
  };
  const closeModal = (cierro) => {
    if (cierro) {
      setOpened(false);
    }
    setConsultar(true);
    setemailSeleccionado(null);
  };
  //modal

  useEffect(() => {
    const cargar = async () => {
      if (!consultar) return;

      try {
        setLoadingLocal(true);
        
        const result = await clienteAxios.get("/Rubros/getAll", {params: {texto: buscar}});
        setEmails({rubros: result.data.rubros, totales: result.data.totales});
        setLoadingLocal(null);
      } catch (e) {}
      setConsultar(null);
    };

    cargar();
  }, [consultar, emailSeleccionado, id]);

  const [DatosForm, LeerForm] = useState({ buscar: "" });
  const { buscar } = DatosForm;

  const onChange = (e) => {
    LeerForm({
      ...DatosForm,
      [e.target.name]: e.target.value,
    });

    if (buscar.length > 1) {
      setConsultar(true);
    }
  };
  
  return (
    <div className="center-block">
      <br></br>
    <h2>Gestión de Rubros</h2>
      <div className="row">

        {!soloLectura ? <div className="col-sm">
          <div className="form-group">

            {!isOpened ? (<BotonAgregar
              onClick={() => {
                openModal();
              }}
              className="btn btn-dark"
            >
              <i className="fa fa-plus-circle"></i> Crear Rubro
            </BotonAgregar>) : null}
          </div>
        </div>: null}
        <div className="col-sm">
          <Input
            key={"buscar"}
            sets={{
              label: "",
              type: "text",
              name: "buscar",
              placeholder: "Ingrese su Busqueda",
              valor: buscar,
            }}
            onChange={onChange}
          />
        </div>

        <br></br>
      </div>

      <br></br>
      {loadingLocal || !emails ? (
        <Spinner />
      ) : (
        <Listar
          emails={emails}
          setemailSeleccionado={setemailSeleccionado}
          setConsultar={setConsultar}
          openModal={openModal}
          soloLectura={soloLectura}
        />
      )}

      <Portal isOpened={isOpened} onClose={closeModal}>
        <Form
          id={id}
          closeModal={closeModal}
          emailSeleccionado={emailSeleccionado}
        />
      </Portal>
    </div>
  );
};

export default Rubros;
