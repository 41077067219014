import React, { useState, useEffect } from "react";
import { clienteAxios } from "../";
import ListarProveedores from "./proveedores/ListarProveedores";
import ListarCatalogo from "../catalogo/ListarCatalogo";

const Catalogo = (props) => {

  const [articulos, setArticulos] = useState(null);

  const [proveedores, SetProveedores] = useState(null);
  const [proveedorSeleccionado, SetProveedorSeleccionado] = useState(null);

  useEffect(() => {
    const cargarProveedores = async () => {
      try {
        if (!proveedores) {
          const resultp = await clienteAxios.get("/Subastas/getProveedores", {
            params: {
              idsubasta: props.match.params.idsubasta,
            },
          });
          console.log(resultp.data);
          SetProveedores(resultp.data.proveedores);
        }
      } catch (e) {}
    };

    cargarProveedores();
  }, [props.match.params.idsubasta, proveedores]);

  useEffect(() => {
    const cargarArticulos = async () => {
      if (!proveedorSeleccionado) return;

      try {
        if (!articulos) {
          const resultp = await clienteAxios.get(
            "/Subastas/getArticulosProveedor",
            {
              params: {
                idsubasta: props.match.params.idsubasta,
                idproveedor: proveedorSeleccionado.id,
              },
            }
          );
          console.log(resultp.data.articulos);
          setArticulos(resultp.data.articulos);
        }
      } catch (e) {}
    };

    cargarArticulos();
  }, [props.match.params.idsubasta, articulos, proveedorSeleccionado]);

  return (
    <div>
      <h2 className="text-center">Catálogo de Subasta</h2>
      {proveedorSeleccionado === null ? (
        <ListarProveedores
          proveedores={proveedores}
          SetProveedorSeleccionado={SetProveedorSeleccionado}
          idsubasta={props.match.params.idsubasta}
        />
      ) : (
        <ListarCatalogo
          catalogo={articulos}
          proveedorSeleccionado={proveedorSeleccionado}
          idsubasta={props.match.params.idsubasta}
          SetProveedorSeleccionado={SetProveedorSeleccionado}
        />
      )}
    </div>
  );
};

export default Catalogo;
