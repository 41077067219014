import React, { useEffect } from "react";
import { BotonEditar } from "../layout/FormsElements";

const Rubro = ({ item, editar, soloLectura, totalesC, totalesP }) => {
  useEffect(() => {}, []);

  const totalProveedor = () => {
    let resultado;
    totalesC.map((item) => (resultado = item.totalComprador));
    return resultado ? resultado : "0";
  };

  const totalComprador = () => {
    let resultado;
    totalesP.map((item) => (resultado = item.totalProveedor));
    return resultado ? resultado : "0";
  };


  return (
    <tr>
      <td className="text-center">{item.rubro}</td>
      <td className="text-center">{item.paraoferente === "0" ? "No" : "Si"}</td>
      <td className="text-center">{totalComprador()}</td>
      <td className="text-center">{item.parademandante === "0" ? "No" : "Si"}</td>
      <td className="text-center">{totalProveedor()}</td>
      <td className="text-center">
        {!soloLectura ? (
          <>
            <BotonEditar editar={editar} item={item} />
            {/* <BotonEliminar eliminar={eliminar} item={item} /> */}
          </>
        ) : null}
      </td>
    </tr>
  );
};

export default Rubro;
