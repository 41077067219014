import React, { useState, useContext, useEffect } from "react";
import { BotoneraForm, Select } from "../layout/FormsElements";
import { Spinner, useAlerta, PersonasContext, clienteAxios } from "./";

const FromUsuarioRubros = ({
  tipoa,
  idpersona,
  closeModal,
  emailSeleccionado,
}) => {
  const personaContext = useContext(PersonasContext);
  const { ocultarMensaje, mensaje } = personaContext;

  const [inicio, setInicio] = useState(true);
  const [loadingLocal, setLoadingLocal] = useState(null);
  const [deshabilitado, setDeshabilitado] = useState(false);
  const [rubros, setRubros] = useState(null);

  const [setAlerta, MostrarAlerta] = useAlerta(null);

  const [DatosForm, LeerForm] = useState({
    idrubro: "",
    productos: "",
    activo: "1",
    tipo: tipoa,
    id_persona: idpersona,
  });

  const { idrubro } = DatosForm;

  useEffect(() => {
    const cargarCombos = async () => {
      try {
        if (!rubros) {
          const resultp = await clienteAxios.get("/Combos/getRubros", {
            params: { tipo: window.location.pathname.startsWith("/usuario/comprador/") ?
              "comprador" : "vendedor"  },
          });
          setRubros(resultp.data.rubros);
        }
      } catch (e) {}
    };

    cargarCombos();
  }, [idpersona, rubros]);

  useEffect(() => {
    const cargar = () => {
      if (emailSeleccionado) {
        LeerForm(emailSeleccionado);
      }
    };

    const alertar = () => {
      if (loadingLocal) {
        setAlerta(null);
      }
      if (inicio) {
        // eslint-disable-next-line
        ocultarMensaje();
        setInicio(null);
        setAlerta(null);
      } else {
        if (mensaje.msg && !loadingLocal) {
          setAlerta({ msg: mensaje.msg, class: mensaje.class });
        }
      }
    };

    cargar();

    alertar();
  }, [mensaje, inicio]);

  const Insertar = async () => {
    if (idrubro === "") {
      setAlerta({ msg: "Rubro es obligatorio", class: "danger" });
      return;
    }

    setAlerta(null);

    try {
      setLoadingLocal(true);
      await clienteAxios.post("/UserRubros/add", DatosForm);
      setLoadingLocal(null);
      setAlerta({ msg: "Se Asigno El Rubro con Exito", class: "success" });
      setDeshabilitado(true);
      closeModal(false);
    } catch (e) {
      setLoadingLocal(null);
      setAlerta({ msg: e.response.data.messages.msg, class: "danger" });
    }
  };

  const Actualizar = async () => {
    if (idrubro === "") {
      setAlerta({ msg: "Rubro es obligatorio", class: "danger" });
      return;
    }

    setAlerta(null);

    try {
      setLoadingLocal(true);
      await clienteAxios.put("/UserRubros/update", DatosForm);
      setLoadingLocal(null);
      setAlerta({ msg: "Se Actualizo con Exito", class: "success" });
      setDeshabilitado(true);
      closeModal(false);
    } catch (e) {
      setLoadingLocal(null);
      setAlerta({ msg: e.response.data.messages.msg, class: "danger" });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!emailSeleccionado) {
      Insertar();
    } else {
      Actualizar();
    }
  };

  const onChange = (e) => {
    LeerForm({
      ...DatosForm,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="center-block">
      <h5 className="text-center">
        {emailSeleccionado ? "Editar " : "Agregar "} Rubro
      </h5>
      <form onSubmit={onSubmit} className="border p-3 form">
        <Select
          key={"idrubro"}
          sets={{
            label: "Rubro ",
            name: "idrubro",
            valor: idrubro,
            opciones: rubros ? rubros : [],
          }}
          onChange={onChange}
        />

       {/*  <Select
          key={"activo"}
          sets={{
            label: "Activo ",
            name: "activo",
            valor: activo,
            opciones: [
              { label: "Si", value: "1" },
              { label: "No", value: "0" },
            ],
          }}
          onChange={onChange}
        /> */}

        {loadingLocal ? <Spinner /> : <MostrarAlerta />}

        <BotoneraForm
          funcionCancelar={closeModal}
          valorfuncion={true}
          deshabilitado={deshabilitado}
        />
      </form>
    </div>
  );
};
export default FromUsuarioRubros;
