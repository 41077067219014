import React from 'react';
import Item from "./Oferente";
import { TituloTabla } from "../../../layout/Estilos";


const ListarOferentes = ({emails}) => {
    return (
        <div className="table-responsive">
        <table
          className="table table-striped"
          style={{ backgroundColor: "white" }}
        >
          <TituloTabla>
            <tr>
              <th scope="col" className="text-center">
                Razon Social
              </th>
              <th scope="col" className="text-center">
                Cantidad
              </th>
            </tr>
          </TituloTabla>
          <tbody>
            {emails.map((item) => (
              <Item
                key={item.razonsocial}
                item={item}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
};

export default ListarOferentes;