import React from "react";
import Item from "./Oferta";
import { TituloTabla } from "../../layout/Estilos";
import { clienteAxios, Swal, useHistory } from ".";

const ListarOfertas = ({
  emails,
  setemailSeleccionado,
  setConsultar,
  openModal,
  soloLectura,
  esadmin,
  etapaSubasta
}) => {
  const history = useHistory();

  const borrar = async (id) => {
    try {
      await clienteAxios.delete("/SubastasProductos/remove", {
        params: { id },
      });
      setConsultar(true);
    } catch (e) {}
  };

  const editar = (email) => {
    setemailSeleccionado(email);
    openModal();
  };

  const eliminar = (email) => {
    Swal.fire({
      title: "Esta Seguro que desea Eliminar?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        borrar(email.id);
      }
    });
  };

  const subasta = (email) => {
    localStorage.setItem('idproducto', email.id);
    localStorage.setItem('nombre', email.nombre);
    localStorage.setItem('idrubro', email.idrubro);

    localStorage.removeItem('idsolicitud');

    history.push('/admin/gestionsubastas');
  };

  return (
    <div className="table-responsive">
      <table
        className="table table-striped"
        style={{ backgroundColor: "white" }}
      >
        <TituloTabla>
          <tr>
            <th scope="col" className="text-center">
              Oferta
            </th>
            <th scope="col" className="text-center">
              Etapa
            </th>
            <th scope="col" className="text-center">
              Acciones
            </th>
          </tr>
        </TituloTabla>
        <tbody>
          {emails.map((item) => (
            <Item
              key={item.id}
              item={item}
              editar={editar}
              eliminar={eliminar}
              soloLectura={soloLectura}
              esadmin={esadmin}
              subasta={subasta}
              etapaSubasta={etapaSubasta}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListarOfertas;
