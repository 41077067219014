import React from "react";
import { BotonEditar } from "../layout/FormsElements";

const Notificacion = ({ item, editar, soloLectura }) => {

  const estado = () => {
    //console.log(precios);
    switch (item.idestado) {
      case "1":
        return "En Evaluacion";
      case "2":
        return "Aprobado";
      case "3":
        return "Rechazado";
      default:
        break;
    }
  };


  return (
    <tr>
      <td className="text-center">{item.created}</td>
      <td className="text-center">{item.asunto}</td>
      <td className="text-center">{estado()}</td>
      <td className="text-center">
        {!soloLectura ? (
          <>
            <BotonEditar editar={editar} item={item} />
            {/* <BotonEliminar eliminar={eliminar} item={item} /> */}
          </>
        ) : null}
      </td>
    </tr>
  );
};

export default Notificacion;
