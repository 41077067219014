import React, { useEffect, useState } from "react";
import { Spinner, clienteAxios, useAlerta } from "..";

import { Link } from "react-router-dom";

const InteresProductoSubata = (props) => {
  const idBien = props.match.params.idproducto;

  /* eslint-disable */
  const [producto, setProducto] = useState(null);
  const [modo, setModo] = useState(null);

  const [consultar, setConsultar] = useState(true);
  const [loadingLocal, setLoadingLocal] = useState(null);
  const [setAlerta, MostrarAlerta] = useAlerta(null);

  const [DatosForm, LeerForm] = useState({
    id: "",
    cantidad: "",
    idproducto: "",
    idsubasta: props.match.params.idsubasta,
    tipousuario: "1", //producto: visitante es comprador 1
    idetapa: "",
    cantidadperfil: "",
  });

  const { cantidad, idsubasta, cantidadperfil, id } = DatosForm;

  useEffect(() => {
    const cargar = async () => {
      setModo("producto");
      LeerForm({ ...DatosForm, idproducto: idBien });

      if (!consultar) return;

      try {
        setConsultar(null);
        const resultp = await clienteAxios.get("/SubastasProductos/get", {
          params: { idproducto: idBien, idsubasta },
        });
        //console.log(resultp.data, resultp.data.producto[0].imagen);
        setProducto({
          info: resultp.data.producto,
          subasta: resultp.data.subasta,
          interes: resultp.data.interes,
        });

        LeerForm({
          ...DatosForm,
          cantidad:
            resultp.data.interes.cantidad.length > 0
              ? resultp.data.interes.cantidad
              : "",
          cantidadperfil:
            resultp.data.interes.cantidadperfil.length > 0
              ? resultp.data.interes.cantidadperfil
              : "",
          id: resultp.data.interes.id.length > 0 ? resultp.data.interes.id : "",
        });
      } catch (e) {}
    };

    cargar();
  }, [consultar, idBien, consultar]);

  const onChange = (e) => {
    LeerForm({
      ...DatosForm,
      [e.target.name]: e.target.value,
      idetapa: producto.subasta.idetapa,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log(DatosForm);

    if (cantidad.trim() === "" && cantidadperfil.trim() === "") {
      setAlerta({ msg: "Cantidad es obligatorio", class: "danger" });
      return;
    }

    setAlerta(null);

    if (id === "") {
      insertar();
    } else {
      actualizar();
    }
  };

  const insertar = async () => {
    try {
      setLoadingLocal(true);
      await clienteAxios.post("/SubastasUserIntereses/add", DatosForm);
      setLoadingLocal(null);
      setAlerta({ msg: "Se Guardo su Interes con Exito", class: "success" });
    } catch (e) {
      setLoadingLocal(null);
      setAlerta({ msg: e.response.data.messages.msg, class: "danger" });
    }
  };

  const actualizar = async () => {
    try {
      setLoadingLocal(true);
      await clienteAxios.post("/SubastasUserIntereses/update", DatosForm);
      setLoadingLocal(null);
      setAlerta({ msg: "Se Guardo su Interes con Exito", class: "success" });
    } catch (e) {
      setLoadingLocal(null);
      setAlerta({ msg: e.response.data.messages.msg, class: "danger" });
    }
  };

  const habilitar = () => {
    /* if (producto.interes) {
      if (producto.interes.cantidad.length > 0) {
        return true;
      }
    }
    return false; */
    return false;
  };

  return (
    <>
      {producto ? (
        <div className="row">
          <div className="text-center">
            <Link to={`/catalogo/${producto.subasta.id}`}>
              Volver a Subasta
            </Link>
          </div>
          <div className="col sm">
            <br></br>
            <img
              width="300px"
              src={`http://pooldecompras.cimcc.org.ar/Api/writable/uploads/${producto.info.imagen}`}
            />
          </div>

          <div className="col sm">
            <br></br>
            <h2 className="text-center">
              Proveedor Le Acerca la Siguiente Oferta:
            </h2>
            <p className="text-center">
              {/* Etapa Subasta: {producto.info.nombre} */}
            </p>
            <p className="text-center">
              Nombre Del Producto: {producto.info.nombre}
            </p>
            <p>Descripcion: {producto.info.descripcion}</p>

            {modo === "producto" ? (
              <>
                <p>
                  <b>Empresa: {producto.info.razonsocial}</b>
                </p>
                <p>
                  Condiciones del Descuento:{" "}
                  {producto.info.condicionesdescuento}
                </p>
                <br></br>
                <b>Promociones:</b>
                <div className="table-responsive">
                  <table
                    className="table table-striped"
                    style={{ backgroundColor: "white" }}
                  >
                    <thead>
                      <tr>
                        <td>Cantidad</td>
                        <td>Precio</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{producto.subasta.cantidadperfil1}</td>
                        <td>{producto.info.precio1}</td>
                      </tr>
                      <tr>
                        <td>{producto.subasta.cantidadperfil2}</td>
                        <td>{producto.info.precio2}</td>
                      </tr>
                      <tr>
                        <td>{producto.subasta.cantidadperfil3}</td>
                        <td>{producto.info.precio3}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <br></br>
              </>
            ) : (
              <p>Cantidad Solicitad: {producto.info.cantidadSolicitada}</p>
            )}
            <hr></hr>
            <form onSubmit={onSubmit}>
              {loadingLocal ? <Spinner /> : <MostrarAlerta />}
              <div className="row">
                Promocion
                <select
                  value={cantidadperfil}
                  onChange={onChange}
                  className="form-select"
                  name="cantidadperfil"
                  disabled={habilitar()}
                >
                  <option value="">Seleccione..</option>
                  <option value={producto.subasta.cantidadperfil1}>
                    Cantidad: {producto.subasta.cantidadperfil1} un. - Precio: ${" "}
                    {producto.info.precio1}
                  </option>
                  <option value={producto.subasta.cantidadperfil2}>
                    Cantidad: {producto.subasta.cantidadperfil2} un. - Precio: ${" "}
                    {producto.info.precio2}
                  </option>
                  <option value={producto.subasta.cantidadperfil3}>
                    Cantidad: {producto.subasta.cantidadperfil3} un. - Precio: ${" "}
                    {producto.info.precio3}
                  </option>
                </select>
                <br></br>
                Unidades
                <input
                  type="number"
                  name="cantidad"
                  value={cantidad}
                  onChange={onChange}
                  disabled={habilitar()}
                  className="form-control"
                ></input>
                <div className="text-center">
                  <br></br>
                  <button type="submit" disabled={habilitar()}>
                    {modo === "producto" ? "Me Interesa Adquirir" : "Oferto"}
                  </button>
                </div>
              </div>
              <br></br>
              <br></br>
            </form>
          </div>
          <div className="text-center">
            <Link to={`/catalogo/${producto.subasta.id}`}>
              <hr></hr>
              Volver a Subasta
            </Link>
            <p></p>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default InteresProductoSubata;
