import React from "react";

const RegistroPlanillaResumen = ({ subasta,  articulos }) => {
  return (
    <>
      <tr>
        <th>Stock máximo Producto 1</th>
        <th></th>
        {articulos.map((item) => (
          <th className="text-center" key={item.id}>{item.stock}</th>
        ))}
      </tr>
      <tr>
        <th>Producto </th>
        <th className="text-center" >{subasta.cantidadperfil1}</th>
        {articulos.map((item) => (
          <th className="text-center" key={item.id}>{item.precio1}</th>
        ))}
      </tr>
      <tr>
        <th></th>
        <th className="text-center">{subasta.cantidadperfil2}</th>
        {articulos.map((item) => (
          <th className="text-center" key={item.id}>{item.precio2}</th>
        ))}
      </tr>
      <tr>
        <th></th>
        <th className="text-center" >{subasta.cantidadperfil3}</th>
        {articulos.map((item) => (
          <th className="text-center" key={item.id}>{item.precio3}</th>
        ))}
      </tr>
    </>
  );
};

export default RegistroPlanillaResumen;
