import React, { useState, useContext, useEffect } from "react";
import { Input, BotoneraForm, Select, TextArea } from "../layout/FormsElements";
import {
  Spinner,
  useAlerta,
  PersonasContext,
  clienteAxios,
  AuthContext,
} from ".";
import { BotonQuitar } from "../layout/Estilos";

const FromProductos = ({ closeModal, emailSeleccionado }) => {
  const personaContext = useContext(PersonasContext);
  const { ocultarMensaje, mensaje } = personaContext;

  const authContext = useContext(AuthContext);
  const { usuario } = authContext;

  const [inicio, setInicio] = useState(true);
  const [loadingLocal, setLoadingLocal] = useState(null);
  const [deshabilitado, setDeshabilitado] = useState(false);

  const [setAlerta, MostrarAlerta] = useAlerta(null);
  const [rubros, setRubros] = useState(null);
  const [file, setFile] = useState(null);

  const [soloLectura, setSoloLectura] = useState(false);

  const [DatosForm, LeerForm] = useState({
    nombre: "",
    descripcion: "",
    condicionesdescuento: "",
    valor: "",
    stock: "",
    idrubro: "",
    idestado: "1",
    urlvideo: "",
    imagen: "",
    id: "",
    precio1: "",
    precio2: "",
    precio3: "",
    cantidad1: "",
    cantidad2: "",
    cantidad3: "",
    idprecio1: "",
    idprecio2: "",
    idprecio3: "",
    comentarios: "",
    fechaVigencia: "",
  });

  const {
    id,
    nombre,
    descripcion,
    condicionesdescuento,
    valor,
    stock,
    idrubro,
    idestado,
    urlvideo,
    imagen,
    precio1,
    precio2,
    precio3,
    cantidad1,
    cantidad2,
    cantidad3,
    idprecio1,
    idprecio2,
    idprecio3,
    comentarios,
    fechaVigencia,
  } = DatosForm;

  useEffect(() => {
    const cargarCombos = async () => {
      try {
        if (!rubros) {
          const resultp = await clienteAxios.get(
            usuario.esadmin === "0"
              ? "/Combos/getRubrosUser"
              : "/Combos/getRubros",
            {
              params: { tipo: "vendedor" },
            }
          );
          setRubros(resultp.data.rubros);
        }
      } catch (e) {}
    };

    cargarCombos();
  }, [id, rubros, usuario.esadmin]);

  useEffect(() => {
    const cargar = () => {
      if (emailSeleccionado) {
        LeerForm({
          nombre: emailSeleccionado.nombre ? emailSeleccionado.nombre : "",
          descripcion: emailSeleccionado.descripcion
            ? emailSeleccionado.descripcion
            : "",
          condicionesdescuento: emailSeleccionado.condicionesdescuento
            ? emailSeleccionado.condicionesdescuento
            : "",
          valor: emailSeleccionado.valor ? emailSeleccionado.valor : "",
          stock: emailSeleccionado.stock ? emailSeleccionado.stock : "",
          idrubro: emailSeleccionado.idrubro ? emailSeleccionado.idrubro : "",
          idestado: emailSeleccionado.idestado
            ? emailSeleccionado.idestado
            : "",
          urlvideo: "",
          imagen: emailSeleccionado.imagen ? emailSeleccionado.imagen : "",
          id: emailSeleccionado.id ? emailSeleccionado.id : "",
          precio1: emailSeleccionado.precios[0]
            ? emailSeleccionado.precios[0].precio
            : "",
          precio2: emailSeleccionado.precios[1]
            ? emailSeleccionado.precios[1].precio
            : "",
          precio3: emailSeleccionado.precios[2]
            ? emailSeleccionado.precios[2].precio
            : "",
          cantidad1: emailSeleccionado.precios[0]
            ? emailSeleccionado.precios[0].cantidad
            : "",
          cantidad2: emailSeleccionado.precios[1]
            ? emailSeleccionado.precios[1].cantidad
            : "",
          cantidad3: emailSeleccionado.precios[2]
            ? emailSeleccionado.precios[2].cantidad
            : "",
          idprecio1: emailSeleccionado.precios[0]
            ? emailSeleccionado.precios[0].id
            : "",
          idprecio2: emailSeleccionado.precios[1]
            ? emailSeleccionado.precios[1].id
            : "",
          idprecio3: emailSeleccionado.precios[2]
            ? emailSeleccionado.precios[2].id
            : "",
          comentarios: emailSeleccionado.comentarios
            ? emailSeleccionado.comentarios
            : "",
          fechaVigencia: emailSeleccionado.fechaVigencia
            ? emailSeleccionado.fechaVigencia
            : "",
        });

        if (emailSeleccionado.imagen) {
          setFile(
            `https://pooldecompras.cimcc.org.ar/Api/writable/uploads/${emailSeleccionado.imagen}`
          );
        }
      }
    };

    const deshabilitar = () => {
      if (emailSeleccionado) {
        if (idestado === "3") {
          setSoloLectura(true);
        }
        if (
          emailSeleccionado.idusuario !== usuario.id &&
          usuario.esadmin === "1"
        ) {
          setSoloLectura(true);
        }
      }
    };

    const alertar = () => {
      if (loadingLocal) {
        setAlerta(null);
      }
      if (inicio) {
        // eslint-disable-next-line
        ocultarMensaje();
        setInicio(null);
        setAlerta(null);
      } else {
        if (mensaje.msg && !loadingLocal) {
          setAlerta({ msg: mensaje.msg, class: mensaje.class });
        }
      }
    };

    cargar();
    alertar();
    deshabilitar();
  }, [mensaje, inicio]);

  const Insertar = async () => {
    if (
      nombre.trim() === "" ||
      descripcion.trim() === "" ||
      condicionesdescuento.trim() === "" ||
      valor.trim() === "" ||
      stock.trim() === "" ||
      idrubro.trim() === "" ||
      fechaVigencia.trim() === "" ||
      imagen.length > 0 
        /* ||
      precio1.trim() === "" ||
      precio2.trim() === "" ||
      precio3.trim() === "" */
    ) {
      setAlerta({ msg: "Todos los campos es obligatorio", class: "danger" });
      return;
    }

    setAlerta(null);

    try {
      setLoadingLocal(true);
      const formData = new FormData();
      formData.append("imagen", imagen);
      formData.append("nombre", nombre);
      formData.append("descripcion", descripcion);
      formData.append("condicionesdescuento", condicionesdescuento);
      formData.append("valor", valor);
      formData.append("stock", stock);
      formData.append("urlvideo", urlvideo);
      formData.append("idrubro", idrubro);
      formData.append("idestado", idestado);
      formData.append("precio1", precio1);
      formData.append("precio2", precio2);
      formData.append("precio3", precio3);
      formData.append("cantidad1", cantidad1);
      formData.append("cantidad2", cantidad2);
      formData.append("cantidad3", cantidad3);
      formData.append("fechaVigencia", fechaVigencia);

      await clienteAxios.post("/Productos/add", formData);
      setLoadingLocal(null);
      setAlerta({ msg: "Se Creo el Producto con Exito", class: "success" });
      setDeshabilitado(true);
      closeModal(false);
    } catch (e) {
      setLoadingLocal(null);
      setAlerta({ msg: e.response.data.messages.msg, class: "danger" });
    }
  };

  const Actualizar = async () => {
    if (
      nombre.trim() === "" ||
      descripcion.trim() === "" ||
      condicionesdescuento.trim() === "" ||
      valor.trim() === "" ||
      stock.trim() === "" ||
      idrubro.trim() === "" ||
      fechaVigencia.trim() === ""
      //imagen.length > 0 ||
      /* precio1.trim() === "" ||
      precio2.trim() === "" ||
      precio3.trim() === "" */
    ) {
      setAlerta({ msg: "Todos los campos son obligatorio", class: "danger" });
      return;
    }
    if (idestado === "3" && comentarios.trim() === "") {
      setAlerta({
        msg: "Cuando Rechace una oferta, debe incluir la causa en Comentarios",
        class: "danger",
      });
      return;
    }

    setAlerta(null);

    try {
      setLoadingLocal(true);
      const formData = new FormData();
      formData.append("id", id);
      formData.append("imagen", imagen);
      formData.append("nombre", nombre);
      formData.append("descripcion", descripcion);
      formData.append("condicionesdescuento", condicionesdescuento);
      formData.append("valor", valor);
      formData.append("stock", stock);
      formData.append("urlvideo", urlvideo);
      formData.append("idrubro", idrubro);
      formData.append("idestado", idestado);
      formData.append("precio1", precio1);
      formData.append("precio2", precio2);
      formData.append("precio3", precio3);
      formData.append("cantidad1", cantidad1);
      formData.append("cantidad2", cantidad2);
      formData.append("cantidad3", cantidad3);
      formData.append("idprecio1", idprecio1);
      formData.append("idprecio2", idprecio2);
      formData.append("idprecio3", idprecio3);
      formData.append("comentarios", comentarios);
      formData.append("fechaVigencia", fechaVigencia);

      await clienteAxios.post("/Productos/update", formData);
      setLoadingLocal(null);
      setAlerta({ msg: "Se Actualizo con Exito", class: "success" });
      setDeshabilitado(true);
      closeModal(false);
    } catch (e) {
      setLoadingLocal(null);
      setAlerta({ msg: e.response.data.messages.msg, class: "danger" });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!emailSeleccionado) {
      Insertar();
    } else {
      Actualizar();
    }
  };

  const onChange = (e) => {
    LeerForm({
      ...DatosForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleChange = (e) => {
    if (e.target.files[0]) {
      setFile(URL.createObjectURL(e.target.files[0]));
      LeerForm({ ...DatosForm, imagen: e.target.files[0] });
    }
  };

  const borrarImagenTemp = () => {
    setFile(null);
    LeerForm({ ...DatosForm, imagen: null });
  };

  return (
    <div className="center-block">
      <h5 className="text-center">
        {emailSeleccionado ? "Editar " : "Agregar "} Producto
      </h5>
      <form onSubmit={onSubmit} className="border p-3 form">
        <div className="row">
          <div className="col-sm">
            <Input
              key={"nombre"}
              sets={{
                label: "Nombre del Producto",
                type: "text",
                name: "nombre",
                placeholder: " ",
                valor: nombre,
                disabled: soloLectura,
              }}
              onChange={onChange}
            />
            <TextArea
              key={"descripcion"}
              sets={{
                label: "Descripcion",
                name: "descripcion",
                placeholder: "ingresar Descripcion",
                valor: descripcion,
                rows: "5",
                cols: "10",
                disabled: soloLectura,
              }}
              onChange={onChange}
            />
            <TextArea
              key={"condicionesdescuento"}
              sets={{
                label: "Condiciones de Descuento",
                name: "condicionesdescuento",
                placeholder: "ingresar condiciones de descuento",
                valor: condicionesdescuento,
                rows: "5",
                cols: "10",
                disabled: soloLectura,
              }}
              onChange={onChange}
            />
            <Select
              key={"idrubro"}
              sets={{
                label: "Rubro ",
                name: "idrubro",
                valor: idrubro,
                disabled: soloLectura,
                opciones: rubros ? rubros : [],
              }}
              onChange={onChange}
            />
            <Input
              key={"fechaVigencia"}
              sets={{
                label: "Fecha Vigencia",
                type: "date",
                name: "fechaVigencia",
                placeholder: " ",
                valor: fechaVigencia,
                disabled: soloLectura,
              }}
              onChange={onChange}
            />
            <Input
              key={"stock"}
              sets={{
                label: "Stock",
                type: "number",
                name: "stock",
                placeholder: " ",
                valor: stock,
                disabled: soloLectura,
              }}
              onChange={onChange}
            />
            <Input
              key={"valor"}
              sets={{
                label: "Precio Unitario",
                type: "number",
                name: "valor",
                placeholder: " ",
                valor: valor,
                disabled: soloLectura,
              }}
              onChange={onChange}
            />
          </div>
          <div className="col-sm">
            <b>Promoción 1</b>

            <Input
              key={"cantidad1"}
              sets={{
                label: "Cantidad",
                type: "number",
                name: "cantidad1",
                placeholder: " ",
                valor: cantidad1,
                disabled: soloLectura,
              }}
              onChange={onChange}
            />
            <Input
              key={"precio1"}
              sets={{
                label: "Precio Total",
                type: "number",
                name: "precio1",
                placeholder: " ",
                valor: precio1,
                disabled: soloLectura,
              }}
              onChange={onChange}
            />
            <hr></hr>
            <b>Promoción 2</b>

            <Input
              key={"cantidad2"}
              sets={{
                label: "Cantidad",
                type: "number",
                name: "cantidad2",
                placeholder: " ",
                valor: cantidad2,
                disabled: soloLectura,
              }}
              onChange={onChange}
            />
            <Input
              key={"precio2"}
              sets={{
                label: "Precio",
                type: "number",
                name: "precio2",
                placeholder: " ",
                valor: precio2,
                disabled: soloLectura,
              }}
              onChange={onChange}
            />
            <hr></hr>
            <b>Promoción 3</b>

            <Input
              key={"cantidad3"}
              sets={{
                label: "Cantidad",
                type: "number",
                name: "cantidad3",
                placeholder: " ",
                valor: cantidad3,
                disabled: soloLectura,
              }}
              onChange={onChange}
            />
            <Input
              key={"precio3"}
              sets={{
                label: "Precio",
                type: "number",
                name: "precio3",
                placeholder: " ",
                valor: precio3,
                disabled: soloLectura,
              }}
              onChange={onChange}
            />
            <input
              type="file"
              className="form-control form-control-md"
              onChange={handleChange}
              name="archivo"
              disabled={soloLectura}
            />
            <br></br>

            {file ? (
              <div className="ml-5">
                <img src={file} width="100px" alt="" />
                <div>
                  {!soloLectura ? (
                    <BotonQuitar
                      type="button"
                      onClick={() => borrarImagenTemp()}
                    >
                      <i className="fa fa-trash"></i>
                    </BotonQuitar>
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="row">
          <hr></hr>
          <div className="col-sm">
            <b>Resultado Evaluacion</b>
            <br></br>
            <br></br>
            <Select
              key={"idestado"}
              sets={{
                disabled: usuario.esadmin === "0" ? true : false,
                label: "Estado ",
                name: "idestado",
                valor: idestado,
                opciones: [
                  { value: "1", label: "En Evaluacion" },
                  { value: "2", label: "Aprobado" },
                  { value: "3", label: "Rechazado" },
                ],
              }}
              onChange={onChange}
            />
          </div>
          <div className="col-sm">
            <TextArea
              key={"comentarios"}
              sets={{
                disabled: usuario.esadmin === "0" ? true : false,
                label: "Comentarios",
                name: "comentarios",
                placeholder: "ingresar Comentarios",
                valor: comentarios,
                rows: "5",
                cols: "10",
              }}
              onChange={onChange}
            />
          </div>
        </div>
        {loadingLocal ? <Spinner /> : <MostrarAlerta />}

        <BotoneraForm
          funcionCancelar={closeModal}
          valorfuncion={true}
          deshabilitado={deshabilitado}
        />
      </form>
    </div>
  );
};
export default FromProductos;
