import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Spinner from '../layout/Spinner';
import useUsuarioAutenticado from '../../hooks/useUsuarioAutenticado';

const RutaPrivada = ({ component: Component, ...props }) => {

    const [data, loading, error, load] = useUsuarioAutenticado();
    const [consultar, setconsultar] = useState(true);

    useEffect(() => {
        if(consultar){
            setconsultar(false);
            
            load();
        }
        
    },[consultar, load]);

    if (!loading) {
        if (error) {
            return (<Route {...props} render={props => <Redirect to="/login" />} />)
        }
        if (data) {
            return (<Route {...props} render={props => <Component {...props} />} />);            
        }
    }

    return (<Spinner />)
}

export default RutaPrivada;