import React, { useState, useEffect } from "react";
import Listar from "./ListarProductos";
import { Spinner, clienteAxios, Portal } from ".";
import { BotonAgregar } from "../layout/Estilos";
import Form from "./FromProductos";
import { Input } from "../layout/FormsElements";

const ProductosUsuario = ({ id, soloLectura }) => {
  const [loadingLocal, setLoadingLocal] = useState(null);
  const [emails, setEmails] = useState(null);

  const [consultar, setConsultar] = useState(true);
  const [itemSeleccionado, setitemSeleccionado] = useState(null);

  const [DatosForm, LeerForm] = useState({ buscar: "" });
  const { buscar } = DatosForm;

  //modal
  const [isOpened, setOpened] = useState(false);
  const openModal = () => {
    setOpened(true);
    window.scrollTo(0, 0);
  };
  const closeModal = (cierro) => {
    if (cierro) {
      setOpened(false);
    }
    setConsultar(true);
    setitemSeleccionado(null);
  };
  //modal

  useEffect(() => {
    const cargar = async () => {
      if (!consultar) return;

      try {
        setLoadingLocal(true);
        const result = await clienteAxios.get("/Productos/getAllUser", {
          params: { texto: buscar },
        });
        setEmails({
          productos: result.data.productos,
          precios: result.data.precios,
          interesados: [],
        });
        setLoadingLocal(null);
      } catch (e) {}
      setConsultar(null);
    };

    cargar();
  }, [consultar, itemSeleccionado, id]);

  const onChange = (e) => {
    LeerForm({
      ...DatosForm,
      [e.target.name]: e.target.value,
    });

    //  if (buscar.length > 1) {
    setConsultar(true);
    // }
  };

  return (
    <div className="center-block">
      <br></br>
      <h2>Gestión de Productos para Ofertar</h2>
      <p>
        Aquí podrá crear y gestionar ofertas. Una vez aprobadas las mismas por
        la Administración del Pool, se informará a los demandantes del mismo
        rubro de la oferta, para evaluar el interés de la misma y si la
        Administración del Pool define que es viable, dará inicio a una Subasta.
      </p>
      <h4>Importante:</h4>
      <p>
        Recuerde que debe ser aprobado por Administración del Sitio para que se
        publique.
      </p>
      <p>
        Una vez aprobado y publicado el producto, no se podrá modificar ni
        eliminar.
      </p>
      <div className="row">
        {!soloLectura ? (
          <div className="col-sm">
            <div className="form-group">
              {!isOpened ? (
                <BotonAgregar
                  onClick={() => {
                    openModal();
                  }}
                  className="btn btn-dark"
                >
                  <i className="fa fa-plus-circle"></i> Crear Producto
                </BotonAgregar>
              ) : null}
            </div>
          </div>
        ) : null}
        <div className="col-sm">
          <Input
            key={"buscar"}
            sets={{
              label: "",
              type: "text",
              name: "buscar",
              placeholder: "Ingrese su Búsqueda",
              valor: buscar,
            }}
            onChange={onChange}
          />
        </div>
        <br></br>
      </div>

      <br></br>
      {loadingLocal || !emails ? (
        <Spinner />
      ) : (
        <Listar
          emails={emails}
          setemailSeleccionado={setitemSeleccionado}
          setConsultar={setConsultar}
          openModal={openModal}
          soloLectura={soloLectura}
          interesados={emails.interesados}
        />
      )}

      <Portal isOpened={isOpened} onClose={closeModal}>
        <Form
          idseleccion={id}
          closeModal={closeModal}
          emailSeleccionado={itemSeleccionado}
        />
      </Portal>
    </div>
  );
};

export default ProductosUsuario;
