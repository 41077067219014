import React, { useState, useEffect } from "react";
import { Spinner, useAlerta, clienteAxios, useHistory } from "../";
import DefinicionDemanda from "./pasos/DefinicionDemanda";
import { Input, TextArea, BotoneraForm } from "../../layout/FormsElements";
import Calendario from "./pasos/Calendario";

const FormSubastas = () => {
  const history = useHistory();

  const [loadingLocal, setLoadingLocal] = useState(null);
  const [deshabilitado, setDeshabilitado] = useState(false);
  const [itemSeleccionado, setitemSeleccionado] = useState(null);

  const [setAlerta, MostrarAlerta] = useAlerta(null);
  const [consultar, setConsultar] = useState(true);

  const [DatosForm, LeerForm] = useState({
    id: "",
    titulo: "",
    descripcion: "",
    idproducto: !localStorage.getItem("idproducto")
      ? ""
      : localStorage.getItem("idproducto"),
    idsolicitud: !localStorage.getItem("idsolicitud")
      ? ""
      : localStorage.getItem("idsolicitud"),
    nombre: localStorage.getItem("nombre"),
    idrubro: localStorage.getItem("idrubro"),
    idestado: "1",
    idetapa: "1",
    cantidadperfil1: "",
    cantidadperfil2: "",
    cantidadperfil3: "",
    fechaAperturaOfertas: "",
    hhAperturaOfertas: "",
    mmAperturaOfertas: "",
    fechaCierreOfertas: "",
    hhCierreOfertas: "",
    mmCierreOfertas: "",
    fechaInicioEtapa1: "",
    hhInicioEtapa1: "",
    mmInicioEtapa1: "",
    fechaFinEtapa1: "",
    hhFinEtapa1: "",
    mmFinEtapa1: "",
    fechaInicioEtapa2a: "",
    hhInicioEtapa2a: "",
    mmInicioEtapa2a: "",
    fechaFinEtapa2a: "",
    hhFinEtapa2a: "",
    mmFinEtapa2a: "",
    fechaInicioEtapa2b: "",
    hhInicioEtapa2b: "",
    mmInicioEtapa2b: "",
    fechaFinEtapa2b: "",
    hhFinEtapa2b: "",
    mmFinEtapa2b: "",
    fechaInicioEtapa3a: "",
    hhInicioEtapa3a: "",
    mmInicioEtapa3a: "",
    fechaFinEtapa3a: "",
    hhFinEtapa3a: "",
    mmFinEtapa3a: "",
    fechaInicioEtapa3b: "",
    hhInicioEtapa3b: "",
    mmInicioEtapa3b: "",
    fechaFinEtapa3b: "",
    hhFinEtapa3b: "",
    mmFinEtapa3b: "",
  });

  const { titulo, descripcion, nombre } = DatosForm;

  useEffect(() => {
    const cargar = async () => {
      if (!consultar) return;

      try {
        setLoadingLocal(true);
        const result = await clienteAxios.get("/Subastas/get", {
          params: {
            id: !localStorage.getItem("idsubasta")
              ? "0"
              : localStorage.getItem("idsubasta"),
            idproducto: !localStorage.getItem("idproducto")
              ? "0"
              : localStorage.getItem("idproducto"),
            idsolicitud: !localStorage.getItem("idsolicitud")
              ? "0"
              : localStorage.getItem("idsolicitud"),
          },
        });
        console.log(result.data);
        setitemSeleccionado({
          subasta: result.data.subasta[0],
          cantidades: result.data.cantidades[0],
        });

        if (result.data.subasta[0]) {
          LeerForm(result.data.subasta[0]);
          // LeerForm({...DatosForm, nombreproducto: result.data.subasta[0].nombre})
        } else {
          // if (itemSeleccionado.cantidades.length > 0) {

          LeerForm({
            ...DatosForm,
            cantidadperfil1: result.data.cantidades[0].min,
            cantidadperfil2: Math.trunc(result.data.cantidades[0].promedio),
            cantidadperfil3: result.data.cantidades[0].max,
          });
          // }
        }
        setLoadingLocal(null);
      } catch (e) {}
      setConsultar(null);
    };

    cargar();
  }, [consultar, itemSeleccionado, DatosForm]);

  useEffect(() => {}, [itemSeleccionado]);

  const onChange = (e) => {
    LeerForm({
      ...DatosForm,
      [e.target.name]: e.target.name.startsWith("mm")
        ? validarMinutos(e.target.value)
        : e.target.name.startsWith("hh")
        ? validarHora(e.target.value)
        : e.target.value,
    });
  };

  const validarHora = (vhh) => {
    let hh = parseInt(vhh);

    if (hh >= 0 && hh <= 23) {
      return hh.toString();
    }

    if (hh > 23) {
      return "23";
    } else {
      return "";
    }
  };

  const validarMinutos = (vmm) => {
    let mm = parseInt(vmm);

    if (mm >= 0 && mm <= 59) {
      return mm.toString();
    }

    if (mm > 59) {
      return "59";
    } else {
      return "";
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (titulo.trim() === "" || descripcion.trim() === "") {
      setAlerta({ msg: "Todos los campos es obligatorio", class: "danger" });
      return;
    }

    setAlerta(null);
    if (!itemSeleccionado.subasta) {
      Insertar();
    } else {
      Actualizar();
    }
  };

  const Insertar = async () => {
    if (titulo.trim() === "" || descripcion.trim() === "") {
      setAlerta({ msg: "Todos los campos es obligatorio", class: "danger" });
      return;
    }

    setAlerta(null);

    try {
      setLoadingLocal(true);
      await clienteAxios.post("/Subastas/add", DatosForm);
      setLoadingLocal(null);
      setAlerta({ msg: "Se Creo la Subasta con Exito", class: "success" });
      setDeshabilitado(true);
    } catch (e) {
      setLoadingLocal(null);
      setAlerta({ msg: e.response.data.messages.msg, class: "danger" });
    }
  };

  const Actualizar = async () => {
    try {
      setLoadingLocal(true);
      await clienteAxios.post("/Subastas/update", DatosForm);
      setLoadingLocal(null);
      setAlerta({ msg: "Se Actualizo con Exito", class: "success" });
    } catch (e) {
      setLoadingLocal(null);
      setAlerta({ msg: e.response.data.messages.msg, class: "danger" });
    }
  };

  const funcionCancelar = () => {
    history.push("/admin/gestionsubastas");
  };

  return (
    <div>
      <h2>Generador de subastas</h2>
      <p>Cree, Configure y Programe Subastas</p>

      <form
        className="border p-3 form"
        onSubmit={onSubmit}
        style={{ marginBottom: "20px" }}
      >
        <Input
          key={"titulo"}
          sets={{
            label: "Titulo ",
            type: "text",
            name: "titulo",
            placeholder: " ",
            valor: titulo,
          }}
          onChange={onChange}
        />
        <TextArea
          key={"descripcion"}
          sets={{
            label: "Descripcion ",
            type: "text",
            name: "descripcion",
            placeholder: " ",
            valor: descripcion,
            col: "5",
            row: "5",
          }}
          onChange={onChange}
        />

        <Input
          key={"nombre"}
          sets={{
            label: "Disparador ",
            type: "text",
            name: "nombre",
            placeholder: " ",
            valor: nombre,
            disabled: true,
          }}
          onChange={null}
        />

        {/*   <div className="text-center">
          <button className="btn btn-sm">Ver</button>
        </div>
        <br></br>
        <hr></hr>*/}
        <br></br>
        <div
          className="accordion"
          id="accordionExample"
          style={{ margin: "10 0 0 10" }}
        >
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                Definición de la Demanda{" "}
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <DefinicionDemanda DatosForm={DatosForm} onChange={onChange} />
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                Calendario{" "}
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <Calendario DatosForm={DatosForm} onChange={onChange} />
              </div>
            </div>
          </div>
        </div>
        <p>
          <br></br>
        </p>
        {loadingLocal ? <Spinner /> : <MostrarAlerta />}

        <BotoneraForm
          funcionCancelar={funcionCancelar}
          valorfuncion={true}
          deshabilitado={deshabilitado}
        />
      </form>

      <p>
        <br></br>
      </p>
    </div>
  );
};

export default FormSubastas;
