import React, { useState, useEffect } from "react";
import Listar from "./ListarUsuarioRubros";
import { Spinner, clienteAxios, Portal } from "./";
import { BotonAgregar } from "../layout/Estilos";
import Form from "./FromUsuarioRubros";
import { Input } from "../layout/FormsElements";

const UsuariosRubros = ({ id, tipo, soloLectura }) => {
  const [loadingLocal, setLoadingLocal] = useState(null);
  const [emails, setEmails] = useState(null);

  const [consultar, setConsultar] = useState(true);
  const [emailSeleccionado, setemailSeleccionado] = useState(null);

  const [DatosForm, LeerForm] = useState({ buscar: "" });
  const { buscar } = DatosForm;

  //modal
  const [isOpened, setOpened] = useState(false);
  const openModal = () => {
    setOpened(true);
    window.scrollTo(0, 0);
  };
  const closeModal = (cierro) => {
    if (cierro) {
      setOpened(false);
    }
    setConsultar(true);
    setemailSeleccionado(null);
  };
  //modal

  useEffect(() => {
    const cargar = async () => {
      if (!consultar) return;

      try {
        setLoadingLocal(true);

        const result = await clienteAxios.get("/UserRubros/getAll", {
          params: { tipo, texto: buscar },
        });
        setEmails(result.data.usuariosrubros);
        setLoadingLocal(null);
      } catch (e) {}
      setConsultar(null);
    };

    cargar();
  }, [consultar, emailSeleccionado, id, tipo]);

  const onChange = (e) => {
    LeerForm({
      ...DatosForm,
      [e.target.name]: e.target.value,
    });

    if (buscar.length > 1) {
      setConsultar(true);
    }
  };

  return (
    <div className="center-block">
      <br></br>
      {tipo === "2" ? (
        <div>
          Gestione los Rubros en los que está interesado ofertar y participar de
          acuerdo a la demanda.
          <p></p>
        </div>
      ) : (
        <div>
          Gestione los Rubros en los que está interesado recibir ofertas y
          participar.
          <p></p>
        </div>
      )}
      <div className="row">
        {!soloLectura ? (
          <div className="col-sm">
            <div className="form-group">
              {!isOpened ? (
                <BotonAgregar
                  onClick={() => {
                    openModal();
                  }}
                  className="btn btn-dark"
                >
                  <i className="fa fa-plus-circle"></i> Agregar Rubro
                </BotonAgregar>
              ) : null}
            </div>
          </div>
        ) : null}
        <div className="col-sm">
          <Input
            key={"buscar"}
            sets={{
              label: "",
              type: "text",
              name: "buscar",
              placeholder: "Ingrese su búsqueda",
              valor: buscar,
            }}
            onChange={onChange}
          />
        </div>

        <br></br>
      </div>

      <br></br>
      {loadingLocal || !emails ? (
        <Spinner />
      ) : (
        <Listar
          emails={emails}
          setemailSeleccionado={setemailSeleccionado}
          setConsultar={setConsultar}
          openModal={openModal}
          soloLectura={soloLectura}
          tipo={tipo}
        />
      )}

      <Portal isOpened={isOpened} onClose={closeModal}>
        <Form
          tipoa={tipo}
          idpersona={id}
          closeModal={closeModal}
          emailSeleccionado={emailSeleccionado}
        />
      </Portal>
    </div>
  );
};

export default UsuariosRubros;
