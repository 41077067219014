import React from "react";

const MiSubasta = ({ item, editar, eliminar, usuario, tipo, interes }) => {
  
  const estado = () => {
    //console.log(item, usuario);
    switch (item.idetapa) {
      case "1":
        return "PROGRAMADA";
      case "2":
        return "RECEPCION DE OFERTAS";
      case "3":
        return "EN ETAPA 1";
      case "4":
        return "EN ETAPA 2 - 1";
      case "5":
        return "EN ETAPA 2 - 2";
      case "6":
        return "EN ETAPA 3 - 1";
      case "7":
        return "EN ETAPA 3 - 2";
      case "8":
        return "SUBASTA FINALIZADA";
      default:
        break;
    }
  };

  return (
    <tr>
      <td className="text-center">{item.titulo}</td>
      <td className="text-center">{estado()}</td>
      <td className="text-center">
        <button
          type="button"
          onClick={() => {
            editar(item);
          }}
        >
          <i className="fa fa-eye"></i>
        </button>
        {usuario.esadmin === "0" && tipo === "proveedor" && item.idetapa !=="8" ? (
          <button
            type="button"
            onClick={() => {
              eliminar(item);
            }}
          >
            <i className="fa fa-money"></i>Ofertar
          </button>
        ) : null}
        {usuario.esadmin === "0" && tipo === "comprador" ? (
          <button
            type="button"
            onClick={() => {
              interes(item);
            }}
          >
            <i className="fa fa-check"></i>Interes
          </button>
        ) : null}
      </td>
    </tr>
  );
};

export default MiSubasta;
