import React, { useReducer } from "react";
import personasReducer from "./personasReducer";
import personasContext from "./personasContext";
import clienteAxios from "../../config/axios";

import {
  REGISTRO_ERROR,
  REGISTRO_EXITO,
  ELIMINAR_PERSONA,
  SELECCIONAR_PERSONA,
  DESELECCIONAR_PERSONA,
  OBTENER_PERSONAS,
  CONSEGUIR_PERSONA,
  CARGANDO,
} from "../../types";

const PersonasState = (props) => {
  const initialState = {
    personaSeleccionada: null,
    personas: [],
    resultado: [],
    cargando: false,
    mensaje: { msg: null, class: null },
  };

  const [state, dispatch] = useReducer(personasReducer, initialState);

  
  const ocultarMensaje = () => {
    dispatch({
      type: REGISTRO_EXITO,
    });
  };

  const eliminarPersona = async (id) => {
    try {
      await clienteAxios.delete("/personas", {
        params: { id },
      });
      dispatch({
        type: ELIMINAR_PERSONA,
        payload: id,
      });
    } catch (e) {
      dispatch({
        type: REGISTRO_ERROR,
        payload: "Hubo un error",
      });
    }
  };

  const seleccionarPersona = (id) => {
    dispatch({
      type: SELECCIONAR_PERSONA,
      payload: id,
    });
  };

  const conseguirPersona = (obj) => {
    dispatch({
      type: CONSEGUIR_PERSONA,
      payload: obj,
    });
  };

  const deseleccionarPersona = () => {
    dispatch({
      type: DESELECCIONAR_PERSONA,
    });
  };

  const obtenerPersonasII = async (texto) => {
    try {
      dispatch({
        type: CARGANDO,
        payload: true,
      });
      const resultado = await clienteAxios.get("/personas", {
        params: { texto },
      });
      dispatch({
        type: OBTENER_PERSONAS,
        payload: resultado.data,
      });
    } catch (error) {}
  };

  return (
    <personasContext.Provider
      value={{
        mensaje: state.mensaje,
        personas: state.personas,
        personaSeleccionada: state.personaSeleccionada,
        resultado: state.resultado,
        cargando: state.cargando,
        
        ocultarMensaje,
        obtenerPersonasII,
        seleccionarPersona,
        deseleccionarPersona,
        eliminarPersona,
        conseguirPersona,
        
      }}
    >
      {props.children}
    </personasContext.Provider>
  );
};

export default PersonasState;
