import React from "react";
import styled from "@emotion/styled";
import { LiSinPunto } from "../../layout/Estilos";
import { Link } from "react-router-dom";

const ItemCatalogo = ({ item }) => {

  const DivCard = styled.div`
  margin-right: 20px;
  width: 270px;
  margin-bottom: 10px;
  

`;

  const Divimagen = styled.div`
    background-image: url(https://pooldecompras.cimcc.org.ar/Api/writable/uploads/${item.imagen});
    width: 268px;
    height: 200px;
    background-image: no-repeat;
    background-image: fixed;
    background-image: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  `;


  return (
    <LiSinPunto style={{marginRight: "100px", width:"200px"}}>
      <DivCard className="card">
        <Link to={`/oferta/ver/${item.id}`} ><Divimagen></Divimagen></Link>
        <div className="card-body">
          <h6 className="card-title">{item.nombre}</h6>
          <div className="card-text">
            <p></p>
          </div>
         
        </div>
      </DivCard>
    </LiSinPunto>
  );
};

export default ItemCatalogo;
