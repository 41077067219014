import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AuthState from "./context/autenticacion/authState";
import RutaPrivada from "./components/rutas/RutaPrivada";

import NuevaCuenta from "./components/auth/NuevaCuenta";
import Layout from "./components/layout/Layout";
import ScrollToTop from "./components/layout/ScrollToTop";
import Main from "./components/pages/Main";
import PageError from "./components/pages/PageError";
import Login from "./components/auth/Login";
import ForgotPassword from "./components/auth/ForgotPassword";
import Terminos from "./components/pages/Terminos";
import Activar from "./components/auth/Activar";
import Dashboard from "./components/auth/Dashboard";
import CambiarPassword from "./components/auth/CambiarPassword";

import Comprador from "./components/perfiles/usuario/comprador/Comprador";
import Vendedor from "./components/perfiles/usuario/vendedor/Vendedor";
import PersonasState from "./context/personas/personasState";
import Rubros from "./components/rubros/Rubros";
import ProductosUsuario from "./components/productos/ProductosUsuario";
import ProductosAdmin from "./components/productos/ProductosAdmin";
import SolicitudesUsuario from "./components/solicitudes/SolicitudesUsuario";
import SolicitudesAdmin from "./components/solicitudes/SolicitudesAdmin";
import FormSubastas from "./components/subastas/configuracion/FormSubastas";
import Notificaciones from "./components/notificaciones/Notificaciones";
import Detalle from "./components/productos/catalogo/Detalle";
import PanelAdmin from "./components/subastas/PanelAdmin";
import OfertarProveedor from "./components/subastas/ofertas/OfertarProveedor";
import Catalogo from "./components/productos/catalogo/Catalogo";
import CatalogoGral from "./components/productos/catalogogral/Catalogo";
import InteresProductoSubasta from "./components/productos/catalogo/InteresProductoSubasta";
import MisSubastas from "./components/subastas/missubastas/MisSubastas";
import PanelUsuario from "./components/subastas/panelUsuario/PanelUsuario";

/* import Usuarios from "./components/usuarios/crud/Usuarios"; */

function App() {
  return (
    <AuthState>
      <PersonasState>
        <Router>
          <Layout>
            <ScrollToTop />
            <Switch>
              <Route exact path="/" component={Main} />
              <Route exact path="/registrate" component={NuevaCuenta} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/olvide" component={ForgotPassword} />
              <Route exact path="/activar/:code" component={Activar} />
              <Route exact path="/terminos" component={Terminos} />


              <RutaPrivada exact path="/notificaciones" component={Notificaciones} />
              <RutaPrivada exact path="/oferta/ver/:id" component={Detalle} />
              <RutaPrivada exact path="/solicitud/ver/:id" component={Detalle} />

              <RutaPrivada exact path="/dashboard" component={Dashboard} />
              <RutaPrivada exact path="/cambiarpassword" component={CambiarPassword} />
              
              <RutaPrivada exact path="/usuario/comprador" component={Comprador} />
              <RutaPrivada exact path="/usuario/vendedor" component={Vendedor} />
              <RutaPrivada exact path="/usuario/productos" component={ProductosUsuario} />
              <RutaPrivada exact path="/usuario/solicitudes" component={SolicitudesUsuario} />
              <RutaPrivada exact path="/usuario/gestionsubastas" component={PanelUsuario} />
              <RutaPrivada exact path="/usuario/ofertarproveedor/:idsubasta/:idusuario" component={OfertarProveedor} />
              
              <RutaPrivada exact path="/catalogo/:idsubasta/" component={Catalogo} />
              <RutaPrivada exact path="/catalogo/" component={CatalogoGral} />
              <RutaPrivada exact path="/catalogo/interes/:idsubasta/:idproducto" component={InteresProductoSubasta} />
              
              <RutaPrivada exact path="/missubastas" component={MisSubastas} />

              <RutaPrivada exact path="/admin/subastas" component={FormSubastas} />
              <RutaPrivada exact path="/admin/gestionsubastas" component={PanelAdmin} />
              <RutaPrivada exact path="/admin/productos" component={ProductosAdmin} />
              <RutaPrivada exact path="/admin/rubros" component={Rubros} />
              <RutaPrivada exact path="/admin/solicitudes" component={SolicitudesAdmin} />
              
              {/* <RutaPrivada exact path="/admin/usuarios" component={Usuarios} /> */}

              <Route component={PageError} />
            </Switch>
          </Layout>
        </Router>
      </PersonasState>
    </AuthState>
  );
}

export default App;
