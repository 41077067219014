import React, { useState, useEffect, useContext } from "react";
import Listar from "./ListarProductos";
import { Spinner, clienteAxios, Portal, AuthContext } from ".";
import { BotonAgregar } from "../layout/Estilos";
import Form from "./FromProductos";
import { Input, Select } from "../layout/FormsElements";

const ProductosAdmin = ({ id, soloLectura }) => {

  const authContext = useContext(AuthContext);
  const { usuario } = authContext;

  const [loadingLocal, setLoadingLocal] = useState(null);
  const [emails, setEmails] = useState(null);

  const [consultar, setConsultar] = useState(true);
  const [itemSeleccionado, setitemSeleccionado] = useState(null);

  const [DatosForm, LeerForm] = useState({ buscar: "", idestado: "0" });
  const { buscar, idestado } = DatosForm;

  //modal
  const [isOpened, setOpened] = useState(false);
  const openModal = () => {
    setOpened(true);
    window.scrollTo(0, 0);
  };
  const closeModal = (cierro) => {
    if (cierro) {
      setOpened(false);
    }
    setConsultar(true);
    setitemSeleccionado(null);
  };
  //modal

  useEffect(() => {
    const cargar = async () => {
      if (!consultar) return;

      try {
        setLoadingLocal(true);
        const result = await clienteAxios.get("/Productos/getAll", {
          params: { texto: buscar, idestado },
        });
        setEmails({
          productos: result.data.productos,
          precios: result.data.precios,
          interesados: result.data.interesados,
        });
        setLoadingLocal(null);
      } catch (e) {}
      setConsultar(null);
    };

    cargar();
  }, [consultar, itemSeleccionado, id]);

  const onChange = (e) => {
    LeerForm({
      ...DatosForm,
      [e.target.name]: e.target.value,
    });

  //  if (buscar.length > 1) {
      setConsultar(true);
   // }
  };

  return (
    <div className="center-block">
      <br></br>
      <h2>Gestion de Productos para Ofertar</h2>
      <h4>Importante:</h4>
      <p>Cree, Apruebe o Rechace Ofertas</p>

      <div className="row">
        {!soloLectura ? (
          <div className="col-sm">
            <div className="form-group">
              {!isOpened ? (
                <BotonAgregar
                  onClick={() => {
                    openModal();
                  }}
                  className="btn btn-dark"
                >
                  <i className="fa fa-plus-circle"></i> Crear Producto
                </BotonAgregar>
              ) : null}
            </div>
          </div>
        ) : null}
        <div className="col-sm">
          <Input
            key={"buscar"}
            sets={{
              label: "Buscar por texto",
              type: "text",
              name: "buscar",
              placeholder: "Ingrese su Busqueda",
              valor: buscar,
            }}
            onChange={onChange}
          />
                      <Select
              key={"idestado"}
              sets={{
                disabled: usuario.esadmin === "0" ? true : false,
                label: "Buscar por Estado ",
                name: "idestado",
                valor: idestado,
                opciones: [
                  { value: "0", label: "Todos los Estados" },
                  { value: "1", label: "En Evaluacion" },
                  { value: "2", label: "Aprobado" },
                  { value: "3", label: "Rechazado" },
                ],
              }}
              onChange={onChange}
            />

        </div>
        <br></br>
      </div>

      <br></br>
      {loadingLocal || !emails ? (
        <Spinner />
      ) : (
        <Listar
          emails={emails}
          setemailSeleccionado={setitemSeleccionado}
          setConsultar={setConsultar}
          openModal={openModal}
          soloLectura={soloLectura}
          esadmin={usuario.esadmin}
          interesados={emails.interesados ? emails.interesados : []}
        />
      )}

      <Portal isOpened={isOpened} onClose={closeModal}>
        <Form
          idseleccion={id}
          closeModal={closeModal}
          emailSeleccionado={itemSeleccionado}
        />
      </Portal>
    </div>
  );
};

export default ProductosAdmin;
