import React, { useContext } from "react";
import { BotonEditar, BotonEliminar } from "../layout/FormsElements";
import { AuthContext } from ".";

const Producto = ({
  item,
  precios,
  editar,
  eliminar,
  soloLectura,
  esadmin,
  subasta,
  interesados
}) => {
  const authContext = useContext(AuthContext);
  const { usuario } = authContext;

  const estado = () => {
    //console.log(interesados[0].total);
    switch (item.idestado) {
      case "1":
        return "En Evaluacion";
      case "2":
        return "Aprobado";
      case "3":
        return "Rechazado";
      default:
        break;
    }
  };


  return (
    <tr>
            <td className="text-center">{item.fecha.replace(' 00:00:00', '')}</td>

      <td className="text-center">{item.nombre}</td>
      <td className="text-center">{item.valor}</td>
      {esadmin === "1" ? (
        <td className="text-center">{item.razonsocial}</td>
      ) : null}
      {esadmin === "1" ? (
        <td className="text-center"> {interesados[0].total} </td>
      ) : null}
      <td className="text-center">{estado()}</td>
      <td className="text-center">
        {!soloLectura || item.idestado !== "2" ? (
          <>
            <BotonEditar editar={editar} item={{ ...item, precios }} />
            {item.idusuario === usuario.id ? (
              <BotonEliminar eliminar={eliminar} item={item} />
            ) : null}
          </>
        ) : null}
        {esadmin === "1" && item.idestado === "2" && interesados[0].total !== ""? (
          <BotonEditar editar={subasta} item={{ ...item, precios }} label={"Subasta"} />
        ) : null}
      </td>
    </tr>
  );
};

export default Producto;
