import UsuariosRubros from "../../../usuariorubros/UsuariosRubros";

const Comprador = () => {
  return (
    <div className="p-2 row justify-content-center ">
      <h2>Configuración para Comprador</h2>
      <div className="card" style={{ width: "90%", margin: "10px" }}>
        <div className="card-body">
          <h5 className="card-title">Rubros</h5>
          <UsuariosRubros tipo={"1"} soloLectura={false} />
        </div>
      </div>
    </div>
  );
};

export default Comprador;
