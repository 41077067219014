import React, { useContext } from "react";
import { BotonEditar, BotonEliminar } from "../../layout/FormsElements";
import { AuthContext } from ".";

const Oferta = ({
  item,
  precios,
  editar,
  eliminar,
  soloLectura,
  esadmin,
  subasta,
  etapaSubasta
}) => {
  const authContext = useContext(AuthContext);
  const { usuario } = authContext;

  const etapa = () => {
    switch (item.idetapa) {
      case "1":
        return "Programada";
      case "2":
        return "Recepcion de Ofertas";
      case "3":
        return "Etapa 1";
      case "4":
        return "Etapa 2 - 1";
      case "5":
        return "Etapa 2 - 2";
      case "6":
        return "Etapa 3 - 1";
      case "7":
        return "Etapa 3 - 2";
      default:
        break;
    }
  };

  return (
    <tr>
      <td className="text-center">{item.descripcion}</td>
      <td className="text-center">{etapa()}</td>

      <td className="text-center">
        {(!soloLectura || item.idestado !== "2")  && item.idetapa === etapaSubasta  ? (
          <>
            <BotonEditar editar={editar} item={{ ...item, precios }} />
            {/* {item.idusuario === usuario.id ? (
              <BotonEliminar eliminar={eliminar} item={item} />
            ) : null} */}
          </>
        ) : null}
        {esadmin === "1" && item.idestado === "2"? (
          <BotonEditar
            editar={subasta}
            item={{ ...item, precios }}
            label={"Subasta"}
          />
        ) : null}
      </td>
    </tr>
  );
};

export default Oferta;
