import React, { useState, useContext, useEffect } from "react";
import { Input, BotoneraForm, Select, TextArea } from "../layout/FormsElements";
import {
  Spinner,
  useAlerta,
  PersonasContext,
  clienteAxios,
  AuthContext,
  useHistory
} from ".";

const FromNotificacion = ({ id, closeModal, emailSeleccionado }) => {
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const { usuario } = authContext;

  const personaContext = useContext(PersonasContext);
  const { ocultarMensaje, mensaje } = personaContext;

  const [inicio, setInicio] = useState(true);
  const [loadingLocal, setLoadingLocal] = useState(null);
  const [deshabilitado, setDeshabilitado] = useState(false);

  const [setAlerta, MostrarAlerta] = useAlerta(null);
  const [rubros, setRubros] = useState(null);
  const [users, setUsers] = useState(null);
  const [subastas, setSubastas] = useState(null);
  const [soloLectura, setSoloLectura] = useState(false);

  const [DatosForm, LeerForm] = useState({
    user_from: "0",
    user_to: usuario.esadmin === "0" ? "1" : "",
    idrubro_to: "0",
    idtipousuario_to: "0",
    idsubasta_to: "0",
    body: "",
    asunto: "",
    link: "",
    id: id,
    idtiponotificacion: usuario.esadmin === "0" ? "1" : "",
    idestado: "1",
    comentarios: "",
  });

  const {
    user_to,
    idrubro_to,
    idtipousuario_to,
    idsubasta_to,
    body,
    asunto,
    link,
    idtiponotificacion,
    idestado,
    comentarios,
  } = DatosForm;

  useEffect(() => {
    const cargar = () => {
      if (emailSeleccionado) {
        LeerForm(emailSeleccionado);
      }
    };

    const alertar = () => {
      if (loadingLocal) {
        setAlerta(null);
      }
      if (inicio) {
        // eslint-disable-next-line
        ocultarMensaje();
        setInicio(null);
        setAlerta(null);
      } else {
        if (mensaje.msg && !loadingLocal) {
          setAlerta({ msg: mensaje.msg, class: mensaje.class });
        }
      }
    };

    const deshabilitar = () => {
      if (idestado !== "1") {
        setSoloLectura(true);
      }
      if (emailSeleccionado) {
        if (
          emailSeleccionado.idusuario !== usuario.id &&
          usuario.esadmin === "1"
        ) {
          setSoloLectura(true);
        }
      }
    };

    const marcarLeido = async () => {
      try {
        await clienteAxios.post("/Notificaciones/setLeido", {
          id: emailSeleccionado.id,
        });
      } catch (e) {}
    };

    cargar();
    deshabilitar();
    alertar();
    marcarLeido();
  }, [mensaje, inicio]);

  useEffect(() => {
    const cargarRubros = async () => {
      if (idtipousuario_to.length > 0) {
        try {
          const resultp = await clienteAxios.get("/Combos/getRubros", {
            params: { tipo: idtipousuario_to },
          });
          setRubros(resultp.data.rubros);
        } catch (e) {}
      }
    };

    const cargarUsuarios = async () => {
      try {
        const resultp = await clienteAxios.get("/Combos/getUsuarios");
        setUsers(resultp.data.usuarios);
      } catch (e) {}
    };

    const cargarSubastas = async () => {
      try {
        const resultp = await clienteAxios.get("/Combos/getSubastas");
        setSubastas(resultp.data.subastas);
      } catch (e) {}
    };

    if (idtiponotificacion === "2") cargarRubros();
    if (idtiponotificacion === "1") cargarUsuarios();
    if (idtiponotificacion === "3") cargarSubastas();
  }, [idtipousuario_to, idtiponotificacion]);

  const Insertar = async () => {
    try {
      setLoadingLocal(true);
      await clienteAxios.post("/Notificaciones/add", DatosForm);
      setLoadingLocal(null);
      setAlerta({ msg: "Se creo la Notificacion con Exito", class: "success" });
      setDeshabilitado(true);
      closeModal(false);
    } catch (e) {
      setLoadingLocal(null);
      setAlerta({ msg: e.response.data.messages.msg, class: "danger" });
    }
  };

  const Actualizar = async () => {
    try {
      setLoadingLocal(true);
      await clienteAxios.post("/Notificaciones/update", DatosForm);
      setLoadingLocal(null);
      setAlerta({ msg: "Se Actualizo con Exito", class: "success" });
      setDeshabilitado(true);
      closeModal(false);
    } catch (e) {
      setLoadingLocal(null);
      setAlerta({ msg: e.response.data.messages.msg, class: "danger" });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (body === "" || asunto === "" || idtiponotificacion === "") {
      setAlerta({
        msg: "Asunto, Mensaje y Tipo de Notificacion son obligatorios",
        class: "danger",
      });
      return;
    }

    if (usuario.esadmin === "1") {
      // a una empresa
      if (idtiponotificacion === "1" && user_to === "") {
        setAlerta({ msg: "Empresa es obligatorio", class: "danger" });
        return;
      }
      // a un rubro
      if (idtiponotificacion === "2" && idrubro_to === "") {
        setAlerta({ msg: "Rubro es obligatorio", class: "danger" });
        return;
      }
      // a una subasta activa
      if (idtiponotificacion === "3" && idsubasta_to === "") {
        setAlerta({ msg: "Subasta es obligatorio", class: "danger" });
        return;
      }
    }

    setAlerta(null);

    if (!emailSeleccionado) {
      Insertar();
    } else {
      Actualizar();
    }
  };

  const onChange = (e) => {
    LeerForm({
      ...DatosForm,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="center-block">
      <h5 className="text-center">
        {emailSeleccionado ? "Editar " : "Agregar "} Notificacion
      </h5>
      <form onSubmit={onSubmit} className="border p-3 form">
        <div className="row">
          {usuario.esadmin === "1" ? (
            <div className="col-sm">
              <b>Para:</b>
              <Select
                key={"idtiponotificacion"}
                sets={{
                  label: "Tipo de Notificacion",
                  name: "idtiponotificacion",
                  valor: idtiponotificacion,
                  disabled: soloLectura,
                  opciones: [
                    { label: "Simple (envio a una Empresa)", value: "1" },
                    { label: "Rubro (envio a un Rubro)", value: "2" },
                    { label: "Subasta Activa", value: "3" },
                    { label: "A Todos", value: "5" },
                  ],
                }}
                onChange={onChange}
              />

              {idtiponotificacion === "1" ? (
                <Select
                  key={"user_to"}
                  sets={{
                    label: "Empresa ",
                    name: "user_to",
                    valor: user_to,
                    disabled: soloLectura,
                    opciones: users ? users : [],
                  }}
                  onChange={onChange}
                />
              ) : null}

              {idtiponotificacion === "2" ? (
                <>
                  <Select
                    key={"idtipousuario_to"}
                    sets={{
                      label: "Tipo ",
                      name: "idtipousuario_to",
                      valor: idtipousuario_to,
                      disabled: soloLectura,
                      opciones: [
                        { label: "Todos", value: "0" },
                        { label: "Comprador", value: "1" },
                        { label: "Proveedor", value: "2" },
                      ],
                    }}
                    onChange={onChange}
                  />
                  <Select
                    key={"idrubro_to"}
                    sets={{
                      label: "Rubro ",
                      name: "idrubro_to",
                      valor: idrubro_to,
                      disabled: soloLectura,
                      opciones: rubros ? rubros : [],
                    }}
                    onChange={onChange}
                  />
                </>
              ) : null}

              {idtiponotificacion === "3" ? (
                <Select
                  key={"idsubasta_to"}
                  sets={{
                    label: "Subasta ",
                    name: "idsubasta_to",
                    valor: idsubasta_to,
                    disabled: soloLectura,
                    opciones: subastas ? subastas : [],
                  }}
                  onChange={onChange}
                />
              ) : null}
            </div>
          ) : null}

          <div className="col-sm">
            <b>Mensaje:</b>
            <Input
              key={"asunto"}
              sets={{
                label: "Asunto ",
                type: "text",
                name: "asunto",
                placeholder: " ",
                valor: asunto,
                disabled: soloLectura,
              }}
              onChange={onChange}
            />

            <TextArea
              key={"body"}
              sets={{
                label: "Mensaje ",
                type: "text",
                name: "body",
                placeholder: " ",
                valor: body,
                rows: "10",
                cols: "20",
                disabled: soloLectura,
              }}
              onChange={onChange}
            />
            <br></br>

            {idtiponotificacion === "4" && (idestado === "2" || usuario.esadmin === "1") ? 
            <div className="text-center">
              <button className="btn" onClick={()=>{history.push(link)}}>Ver Detalle</button>
              <br></br>
              <br></br>
            </div> : null}
          </div>
        </div>

        <div className="row">
          <hr></hr>
          <div className="col-sm">
            <b>Resultado Evaluacion</b>
            <br></br>
            <br></br>
            <Select
              key={"idestado"}
              sets={{
                disabled: usuario.esadmin === "0" ? true : false,
                label: "Estado ",
                name: "idestado",
                valor: idestado,
                opciones: [
                  { value: "1", label: "En Evaluacion" },
                  { value: "2", label: "Aprobado" },
                  { value: "3", label: "Rechazado" },
                ],
              }}
              onChange={onChange}
            />
          </div>
          <div className="col-sm">
            <TextArea
              key={"comentarios"}
              sets={{
                disabled: usuario.esadmin === "0" ? true : false,
                label: "Comentarios",
                name: "comentarios",
                placeholder: "ingresar Comentarios",
                valor: comentarios,
                rows: "5",
                cols: "10",
              }}
              onChange={onChange}
            />
          </div>
        </div>

        {loadingLocal ? <Spinner /> : <MostrarAlerta />}

        <BotoneraForm
          funcionCancelar={closeModal}
          valorfuncion={true}
          deshabilitado={deshabilitado}
        />
      </form>
    </div>
  );
};
export default FromNotificacion;
