import React, { useEffect, useContext, useState } from "react";
import { Spinner, clienteAxios, useAlerta, AuthContext } from "..";

const Detalle = (props) => {
  const idBien = props.match.params.id;
  const authContext = useContext(AuthContext);
  const { usuario } = authContext;

  /* eslint-disable */
  const [producto, setProducto] = useState(null);
  const [modo, setModo] = useState(null);
  const [consultar, setConsultar] = useState(true);
  const [loadingLocal, setLoadingLocal] = useState(null);
  const [setAlerta, MostrarAlerta] = useAlerta(null);

  const [DatosForm, LeerForm] = useState({
    id: "",
    cantidad: "",
    idproducto: "",
    idsolicitud: "",
    idsubasta: "",
    tipousuario: modo === "producto" ? "1" : "2", //producto: visitante es comprador 1
  });

  const { cantidad } = DatosForm;

  useEffect(() => {
    const origen = () => {
      let url = "";
      if (window.location.pathname.startsWith("/oferta/ver/")) {
        setModo("producto");
        LeerForm({ ...DatosForm, idproducto: idBien });
        url = "/Productos/get";
      }
      if (window.location.pathname.startsWith("/solicitud/ver/")) {
        LeerForm({ ...DatosForm, idsolicitud: idBien });
        setModo("solicitud");
        url = "/Solicitudes/get";
      }
      return url;
    };

    const cargar = async (url) => {
      try {
        const resultp = await clienteAxios.get(url, {
          params: { id: idBien },
        });
        //console.log(resultp.data, resultp.data.producto[0].imagen);
        setProducto({
          info: resultp.data.producto[0],
          precios: resultp.data.precios,
          interes: resultp.data.interes,
        });
        LeerForm({
          ...DatosForm,
          cantidad:
            resultp.data.interes.cantidad.length > 0
              ? resultp.data.interes.cantidad
              : "",
        });
      } catch (e) {}
    };

    const url = origen();
    cargar(url);
  }, [consultar, idBien, modo]);

  const onChange = (e) => {
    LeerForm({
      ...DatosForm,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (cantidad.trim() === "") {
      setAlerta({ msg: "Cantidad es obligatorio", class: "danger" });
      return;
    }

    setAlerta(null);

    try {
      setLoadingLocal(true);
      await clienteAxios.post("/UserIntereses/add", DatosForm);
      setLoadingLocal(null);
      setAlerta({ msg: "Se Guardo su Interes con Exito", class: "success" });
    } catch (e) {
      setLoadingLocal(null);
      setAlerta({ msg: e.response.data.messages.msg, class: "danger" });
    }
  };

  const habilitar = () => {
    if (producto.interes) {
      if (producto.interes.cantidad.length > 0) {
        return true;
      }
    }
    return false;
  };
  return (
    <>
      {producto ? (
        <div className="row">
          {modo === "producto" ? (
            <div className="col sm">
              <br></br>
              <img
                width="300px"
                src={`http://pooldecompras.cimcc.org.ar/Api/writable/uploads/${producto.info.imagen}`}
              />
            </div>
          ) : null}
          <div className="col sm">
            <br></br>
            <h2 className="text-center">
              {modo === "producto"
                ? "Proveedor Le Acerca la Siguiente Oferta:"
                : "Comprador Solicita Oferta:"}
            </h2>
            <p className="text-center">
              Nombre Del Producto: {producto.info.nombre}
            </p>
            <p>Descripcion: {producto.info.descripcion}</p>

            {modo === "producto" ? (
              <>
                <p>
                  <b>Empresa: {producto.info.razonsocial}</b>
                </p>
                <p>
                  Condiciones del Descuento:{" "}
                  {producto.info.condicionesdescuento}
                </p>
                <p>Stock: {producto.info.stock}</p>
                <p>Vigencia de la Promocion: {producto.info.fechaVigencia}</p>
                <br></br>
                <b>Promociones:</b>
                <div className="table-responsive">
                  <table
                    className="table table-striped"
                    style={{ backgroundColor: "white" }}
                  >
                    <thead>
                      <tr>
                        <td>Cantidad</td>
                        <td>Precio</td>
                      </tr>
                    </thead>
                    <tbody>
                      {producto.precios.map((item) => (
                        <tr key={item.id}>
                          <td>{item.cantidad}</td>
                          <td>{item.precio}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <br></br>
              </>
            ) : (
              <p>Cantidad Solicitad: {producto.info.cantidadSolicitada}</p>
            )}
            <hr></hr>
            <form onSubmit={onSubmit}>
              {loadingLocal ? <Spinner /> : <MostrarAlerta />}
              {usuario.esadmin === "0" ? (
                <div className="row">
                  <div className="col sm">
                    <button type="submit" disabled={habilitar()}>
                      {modo === "producto" ? "Me Interesa Adquirir" : "Oferto"}
                    </button>
                  </div>
                  <div className="col sm">
                    <input
                      type="number"
                      name="cantidad"
                      value={cantidad}
                      onChange={onChange}
                      disabled={habilitar()}
                    ></input>{" "}
                    Unidades
                  </div>
                </div>
              ) : null}
              <br></br>
              <br></br>
            </form>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default Detalle;
