import React, {useState, useEffect} from "react";
import Listar from "./ListarOferentes";
import {clienteAxios, Spinner} from "../../";
import { BotoneraForm } from "../../../layout/FormsElements";

const ResumenOferentes = ({ closeModal, idetapa, idsubasta, productoSeleccionado }) => {
  const [loadingLocal, setLoadingLocal] = useState(null);
  const [emails, setEmails] = useState(null);

  const [consultar, setConsultar] = useState(true);

  useEffect(() => {
    const cargar = async () => {
      if (!consultar) return;

      try {
        setLoadingLocal(true);

        const result = await clienteAxios.get("/Subastas/getOferentesSubasta", {
          params: { idsubasta, idetapa, idproducto: productoSeleccionado.id },
        });
        setEmails( result.data.oferentes );
        setLoadingLocal(null);
      } catch (e) {}
      setConsultar(null);
    };

    cargar();
  }, [consultar, idetapa, idsubasta, productoSeleccionado.id]);

  return (
    <div className="center-block">
      <br></br>
      <h2>Oferentes</h2>

      <br></br>
      {loadingLocal || !emails ? <Spinner /> : <Listar emails={emails} />}
      <br></br>
      <BotoneraForm
          funcionCancelar={closeModal}
          valorfuncion={true}
          deshabilitado={true}
        />
      
    </div>
  );
};

export default ResumenOferentes;
