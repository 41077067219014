import React from "react";
import ItemHeader from "../../layout/ItemHeader";

const HeaderUsuario = () => {
  return (
    <div className="navbar-nav">
      <ItemHeader label="Escritorio" to="dashboard" />
      <ItemHeader label="Catalogo General" to="missubastas" />
    </div>
  );
};

export default HeaderUsuario;
