import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Boton } from "../layout/Estilos";
import { AuthContext, useAlerta, useRest, Spinner } from "./";
import styled from "@emotion/styled";

const ContenedorLogin = styled.div`
@media only screen and (max-width: 600px) {
    width: 90%;
    margin: 20px;
    min-height: 600px;
  }

  @media (min-width: 600px) {
    width: 50%;
    margin: auto;
    min-height: 600px;
  }
`;


const ForgotPassword = () => {
  const authContext = useContext(AuthContext);
  const { mensaje, ocultarMensaje, OlvidePassword } = authContext;
  const [setAlerta, MostrarAlerta] = useAlerta(null);
  const [loading, load] = useRest();
  const [inicio, setInicio] = useState(true);

  useEffect(() => {
    const alertar = () => {
      if (mensaje.msg) {
        setAlerta({ msg: mensaje.msg, class: mensaje.class });

        if (mensaje.class === "success") {
          LeerForm({ email: "" });
        }
      }

      if (loading) {
        setAlerta(null);
      }
      if (inicio) {
        /* eslint-disable */
        ocultarMensaje();
        setInicio(null);
        setAlerta(null);
      }
    };

    alertar();
  }, [mensaje, loading]);

  const [DatosForm, LeerForm] = useState({ email: "" });
  const { email } = DatosForm;

  const onSubmit = (e) => {
    e.preventDefault();

    if (email.trim() === "") {
      setAlerta({
        msg: "Debe ingresar una dirección de Email",
        class: "danger",
      });
      return;
    }

    const api = {
      url: "/auth/recuperar",
      type: "post",
      datosJson: {
        email,
      },
      getParams: null,
      funcion: OlvidePassword,
    };

    load(api);
  };

  const onChange = (e) => {
    LeerForm({
      ...DatosForm,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <ContenedorLogin>
      <br></br>
      <h2 className="text-center">Olvide Mi Contraseña</h2>
      <br></br>
      <div style={{ margin: "auto" }}>
          <p className="justify-content-center text-center">
            Ingrese la dirección de Email, con la que se registro. Luego
            presione el botón "Solicitar Recuperación", esta acción realizará el
            envio de un email con un link para que ingrese una nueva Contraseña.{" "}
          </p>
         
          <form onSubmit={onSubmit}>
            <div className="form-group">
              <input
                type="email"
                name="email"
                className="form-control"
                id="email"
                aria-describedby="emailHelp"
                placeholder="Ingrese el email"
                onChange={onChange}
                value={email}
              />
            </div>
            <br></br>
            {loading ? <Spinner /> : <MostrarAlerta />}

            <div className="form-group">
              <br></br>
              <Boton type="submit" style={{width:"100%"}} className="btn btn-primary btn-block btn-lg">
                Solicitar Recuperación
              </Boton>
              <br></br>
              <br></br>
            </div>
          </form>
        </div>

        <div className="text-center">
          <br></br>
          <Link aria-label="Obtener Cuenta" to={"/registrate"}>
            Registrarse
          </Link>{" "}
          -{" "}
          <Link aria-label="Ingresar" to={"/login"}>
            Ingresar
          </Link>
        </div>

    </ContenedorLogin>
  );
};

export default ForgotPassword;
