import React, { useState, useContext, useEffect } from "react";
import { Input, BotoneraForm, Select, TextArea } from "../layout/FormsElements";
import {
  Spinner,
  useAlerta,
  PersonasContext,
  clienteAxios,
  AuthContext,
} from ".";

const FromSolicitudes = ({ closeModal, emailSeleccionado }) => {
  const personaContext = useContext(PersonasContext);
  const { ocultarMensaje, mensaje } = personaContext;

  const authContext = useContext(AuthContext);
  const { usuario } = authContext;

  const [inicio, setInicio] = useState(true);
  const [loadingLocal, setLoadingLocal] = useState(null);
  const [deshabilitado, setDeshabilitado] = useState(false);

  const [setAlerta, MostrarAlerta] = useAlerta(null);
  const [rubros, setRubros] = useState(null);

  const [soloLectura, setSoloLectura] = useState(false);

  const [DatosForm, LeerForm] = useState({
    nombre: "",
    descripcion: "",
    idrubro: "",
    idestado: "1",
    id: "",
    cantidad: "",
    observaciones: "",
  });

  const {
    id,
    nombre,
    descripcion,
    idrubro,
    idestado,
    cantidad,
    observaciones,
  } = DatosForm;

  useEffect(() => {
    const cargarCombos = async () => {
      try {
        if (!rubros) {
          const resultp = await clienteAxios.get(
            usuario.esadmin === "0"
              ? "/Combos/getRubrosUser"
              : "/Combos/getRubros",
            {
              params: { tipo: "comprador" },
            }
          );
          setRubros(resultp.data.rubros);
        }
      } catch (e) {}
    };

    cargarCombos();
  }, [id, rubros]);

  useEffect(() => {
    const cargar = () => {
      if (emailSeleccionado) {
        LeerForm({
          descripcion: emailSeleccionado.descripcion
            ? emailSeleccionado.descripcion
            : "",
          idrubro: emailSeleccionado.idrubro ? emailSeleccionado.idrubro : "",
          idestado: emailSeleccionado.idestado
            ? emailSeleccionado.idestado
            : "",
          id: emailSeleccionado.id ? emailSeleccionado.id : "",
          cantidad: emailSeleccionado.cantidad ? emailSeleccionado.cantidad : "",
          observaciones: emailSeleccionado.observaciones ? emailSeleccionado.observaciones: "",
          nombre: emailSeleccionado.nombre ? emailSeleccionado.nombre : "",
        
        });
      }
    };

    const deshabilitar = () => {
      if (emailSeleccionado) {
        if (idestado === "3") {
          setSoloLectura(true);
        }
        if (
          emailSeleccionado.idusuario !== usuario.id &&
          usuario.esadmin === "1"
        ) {
          setSoloLectura(true);
        }
      }
    };

    const alertar = () => {
      if (loadingLocal) {
        setAlerta(null);
      }
      if (inicio) {
        // eslint-disable-next-line
        ocultarMensaje();
        setInicio(null);
        setAlerta(null);
      } else {
        if (mensaje.msg && !loadingLocal) {
          setAlerta({ msg: mensaje.msg, class: mensaje.class });
        }
      }
    };

    cargar();
    alertar();
    deshabilitar();
  }, [mensaje, inicio]);

  const Insertar = async () => {
    if (
      descripcion.trim() === "" ||
      idrubro.trim() === "" ||
      cantidad.trim() === ""
    ) {
      setAlerta({ msg: "Todos los campos es obligatorio", class: "danger" });
      return;
    }

    setAlerta(null);

    try {
      setLoadingLocal(true);

      await clienteAxios.post("/Solicitudes/add", DatosForm);
      setLoadingLocal(null);
      setAlerta({ msg: "Se Creo la Solicitud con Exito", class: "success" });
      setDeshabilitado(true);
      closeModal(false);
    } catch (e) {
      setLoadingLocal(null);
      setAlerta({ msg: e.response.data.messages.msg, class: "danger" });
    }
  };

  const Actualizar = async () => {
    if (
      descripcion.trim() === "" ||
      idrubro.trim() === "" ||
      cantidad.trim() === ""
    ) {
      setAlerta({ msg: "Todos los campos es obligatorio", class: "danger" });
      return;
    }

    if (idestado === "3" && observaciones.trim() === "") {
      setAlerta({
        msg: "Cuando Rechace una oferta, debe incluir la causa en Comentarios",
        class: "danger",
      });
      return;
    }

    setAlerta(null);

    try {
      setLoadingLocal(true);

      await clienteAxios.post("/Solicitudes/update", DatosForm);
      setLoadingLocal(null);
      setAlerta({ msg: "Se Actualizo con Exito", class: "success" });
      setDeshabilitado(true);
      closeModal(false);
    } catch (e) {
      setLoadingLocal(null);
      setAlerta({ msg: e.response.data.messages.msg, class: "danger" });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!emailSeleccionado) {
      Insertar();
    } else {
      Actualizar();
    }
  };

  const onChange = (e) => {
    LeerForm({
      ...DatosForm,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="center-block">
      <h5 className="text-center">
        {emailSeleccionado ? "Editar " : "Agregar "} Solicitud de Ofertas
      </h5>
      <form onSubmit={onSubmit} className="border p-3 form">
        <div className="row">
          <div className="col-sm">
            <Input
              key={"nombre"}
              sets={{
                label: "Nombre del Producto",
                type: "text",
                name: "nombre",
                placeholder: " ",
                valor: nombre,
                disabled: soloLectura,
              }}
              onChange={onChange}
            />
            <TextArea
              key={"descripcion"}
              sets={{
                label: "Descripcion",
                name: "descripcion",
                placeholder: "ingresar Descripcion",
                valor: descripcion,
                rows: "5",
                cols: "10",
                disabled: soloLectura,
              }}
              onChange={onChange}
            />
          </div>
          <div className="col-sm">
            <Input
              key={"cantidad"}
              sets={{
                label: "Cantidad Solicitada",
                type: "number",
                name: "cantidad",
                placeholder: " ",
                valor: cantidad,
                disabled: soloLectura,
              }}
              onChange={onChange}
            />
            <Select
              key={"idrubro"}
              sets={{
                label: "Rubro ",
                name: "idrubro",
                valor: idrubro,
                disabled: soloLectura,
                opciones: rubros ? rubros : [],
              }}
              onChange={onChange}
            />
          </div>
        </div>
        <div className="row">
          <hr></hr>
          <div className="col-sm">
            <b>Resultado Evaluacion</b>
            <br></br>
            <br></br>
            <Select
              key={"idestado"}
              sets={{
                disabled: usuario.esadmin === "0" ? true : false,
                label: "Estado ",
                name: "idestado",
                valor: idestado,
                opciones: [
                  { value: "1", label: "En Evaluacion" },
                  { value: "2", label: "Aprobado" },
                  { value: "3", label: "Rechazado" },
                ],
              }}
              onChange={onChange}
            />
          </div>
          <div className="col-sm">
            <TextArea
              key={"observaciones"}
              sets={{
                disabled: usuario.esadmin === "0" ? true : false,
                label: "Comentarios",
                name: "observaciones",
                placeholder: "ingresar Comentarios",
                valor: observaciones,
                rows: "5",
                cols: "10",
              }}
              onChange={onChange}
            />
          </div>
        </div>
        {loadingLocal ? <Spinner /> : <MostrarAlerta />}

        <BotoneraForm
          funcionCancelar={closeModal}
          valorfuncion={true}
          deshabilitado={deshabilitado}
        />
      </form>
    </div>
  );
};
export default FromSolicitudes;
