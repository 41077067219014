import React, { useState } from "react";
import { Input } from "../../../layout/FormsElements";

const Calendario = ({ DatosForm, onChange }) => {
  const [soloLectura] = useState(false);

  const {
    fechaAperturaOfertas,
    hhAperturaOfertas,
    mmAperturaOfertas,
    fechaCierreOfertas,
    hhCierreOfertas,
    mmCierreOfertas,
    fechaInicioEtapa1,
    hhInicioEtapa1,
    mmInicioEtapa1,
    fechaFinEtapa1,
    hhFinEtapa1,
    mmFinEtapa1,
    fechaInicioEtapa2a,
    hhInicioEtapa2a,
    mmInicioEtapa2a,
    fechaFinEtapa2a,
    hhFinEtapa2a,
    mmFinEtapa2a,
    fechaInicioEtapa2b,
    hhInicioEtapa2b,
    mmInicioEtapa2b,
    fechaFinEtapa2b,
    hhFinEtapa2b,
    mmFinEtapa2b,
    fechaInicioEtapa3a,
    hhInicioEtapa3a,
    mmInicioEtapa3a,
    fechaFinEtapa3a,
    hhFinEtapa3a,
    mmFinEtapa3a,
    fechaInicioEtapa3b,
    hhInicioEtapa3b,
    mmInicioEtapa3b,
    fechaFinEtapa3b,
    hhFinEtapa3b,
    mmFinEtapa3b,
  } = DatosForm;



  return (
    <div>
      <b>Recepción de Ofertas:</b>

      <Input
        key={"fechaAperturaOfertas"}
        sets={{
          label: "Inicio",
          type: "date",
          name: "fechaAperturaOfertas",
          placeholder: " ",
          valor: fechaAperturaOfertas,
          disabled: soloLectura,
        }}
        onChange={onChange}
      />
      <Input
        key={"hhAperturaOfertas"}
        sets={{
          label: "Hora",
          type: "number",
          name: "hhAperturaOfertas",
          placeholder: " ",
          valor: hhAperturaOfertas,
          disabled: soloLectura,
          min: "0",
          max: "23",
        }}
        onChange={onChange}
      />
      <Input
        key={"mmAperturaOfertas"}
        sets={{
          label: "Minutos",
          type: "number",
          name: "mmAperturaOfertas",
          placeholder: " ",
          valor: mmAperturaOfertas,
          disabled: soloLectura,
          min: "0",
          max: "59",
        }}
        onChange={onChange}
      />
      <br></br>
      <Input
        key={"fechaCierreOfertas"}
        sets={{
          label: "Fin",
          type: "date",
          name: "fechaCierreOfertas",
          placeholder: " ",
          valor: fechaCierreOfertas,
          disabled: soloLectura,
        }}
        onChange={onChange}
      />
      <Input
        key={"hhCierreOfertas"}
        sets={{
          label: "Hora",
          type: "number",
          name: "hhCierreOfertas",
          placeholder: " ",
          valor: hhCierreOfertas,
          disabled: soloLectura,
          min: "0",
          max: "23",
        }}
        onChange={onChange}
      />
      <Input
        key={"mmCierreOfertas"}
        sets={{
          label: "Minutos",
          type: "number",
          name: "mmCierreOfertas",
          placeholder: " ",
          valor: mmCierreOfertas,
          disabled: soloLectura,
          min: "0",
          max: "59",
        }}
        onChange={onChange}
      />

      <hr></hr>
      <b>Subasta Etapa 1:</b>
      <Input
        key={"fechaInicioEtapa1"}
        sets={{
          label: "Inicio",
          type: "date",
          name: "fechaInicioEtapa1",
          placeholder: " ",
          valor: fechaInicioEtapa1,
          disabled: soloLectura,
        }}
        onChange={onChange}
      />
      <Input
        key={"hhInicioEtapa1"}
        sets={{
          label: "Hora",
          type: "number",
          name: "hhInicioEtapa1",
          placeholder: " ",
          valor: hhInicioEtapa1,
          disabled: soloLectura,
          min: "0",
          max: "23",
        }}
        onChange={onChange}
      />
      <Input
        key={"mmInicioEtapa1"}
        sets={{
          label: "Minutos",
          type: "number",
          name: "mmInicioEtapa1",
          placeholder: " ",
          valor: mmInicioEtapa1,
          disabled: soloLectura,
          min: "0",
          max: "59",
        }}
        onChange={onChange}
      />
      <br></br>
      <Input
        key={"fechaFinEtapa1"}
        sets={{
          label: "Fin",
          type: "date",
          name: "fechaFinEtapa1",
          placeholder: " ",
          valor: fechaFinEtapa1,
          disabled: soloLectura,
        }}
        onChange={onChange}
      />
      <Input
        key={"hhFinEtapa1"}
        sets={{
          label: "Hora",
          type: "number",
          name: "hhFinEtapa1",
          placeholder: " ",
          valor: hhFinEtapa1,
          disabled: soloLectura,
          min: "0",
          max: "23",
        }}
        onChange={onChange}
      />
      <Input
        key={"mmFinEtapa1"}
        sets={{
          label: "Minutos",
          type: "number",
          name: "mmFinEtapa1",
          placeholder: " ",
          valor: mmFinEtapa1,
          disabled: soloLectura,
          min: "0",
          max: "59",
        }}
        onChange={onChange}
      />

      <br></br>
      <hr></hr>
      <b>Subasta Etapa 2 - Fase 1:</b>
      <Input
        key={"fechaInicioEtapa2a"}
        sets={{
          label: "Inicio",
          type: "date",
          name: "fechaInicioEtapa2a",
          placeholder: " ",
          valor: fechaInicioEtapa2a,
          disabled: soloLectura,
        }}
        onChange={onChange}
      />
      <Input
        key={"hhInicioEtapa2a"}
        sets={{
          label: "Hora",
          type: "number",
          name: "hhInicioEtapa2a",
          placeholder: " ",
          valor: hhInicioEtapa2a,
          disabled: soloLectura,
          min: "0",
          max: "23",
        }}
        onChange={onChange}
      />
      <Input
        key={"mmInicioEtapa2a"}
        sets={{
          label: "Minutos",
          type: "number",
          name: "mmInicioEtapa2a",
          placeholder: " ",
          valor: mmInicioEtapa2a,
          disabled: soloLectura,
          min: "0",
          max: "59",
        }}
        onChange={onChange}
      />
      <br></br>
      <Input
        key={"fechaFinEtapa2a"}
        sets={{
          label: "Fin",
          type: "date",
          name: "fechaFinEtapa2a",
          placeholder: " ",
          valor: fechaFinEtapa2a,
          disabled: soloLectura,
        }}
        onChange={onChange}
      />
      <Input
        key={"hhFinEtapa2a"}
        sets={{
          label: "Hora",
          type: "number",
          name: "hhFinEtapa2a",
          placeholder: " ",
          valor: hhFinEtapa2a,
          disabled: soloLectura,
          min: "0",
          max: "23",
        }}
        onChange={onChange}
      />
      <Input
        key={"mmFinEtapa2a"}
        sets={{
          label: "Minutos",
          type: "number",
          name: "mmFinEtapa2a",
          placeholder: " ",
          valor: mmFinEtapa2a,
          disabled: soloLectura,
          min: "0",
          max: "59",
        }}
        onChange={onChange}
      />
      <br></br>
      <hr></hr>
      <b>Subasta Etapa 2 - Fase 2:</b>
      <Input
        key={"fechaInicioEtapa2b"}
        sets={{
          label: "Inicio",
          type: "date",
          name: "fechaInicioEtapa2b",
          placeholder: " ",
          valor: fechaInicioEtapa2b,
          disabled: soloLectura,
        }}
        onChange={onChange}
      />
      <Input
        key={"hhInicioEtapa2b"}
        sets={{
          label: "Hora",
          type: "number",
          name: "hhInicioEtapa2b",
          placeholder: " ",
          valor: hhInicioEtapa2b,
          disabled: soloLectura,
          min: "0",
          max: "23",
        }}
        onChange={onChange}
      />
      <Input
        key={"mmInicioEtapa2b"}
        sets={{
          label: "Minutos",
          type: "number",
          name: "mmInicioEtapa2b",
          placeholder: " ",
          valor: mmInicioEtapa2b,
          disabled: soloLectura,
          min: "0",
          max: "59",
        }}
        onChange={onChange}
      />
      <br></br>
      <Input
        key={"fechaFinEtapa2b"}
        sets={{
          label: "Fin",
          type: "date",
          name: "fechaFinEtapa2b",
          placeholder: " ",
          valor: fechaFinEtapa2b,
          disabled: soloLectura,
        }}
        onChange={onChange}
      />
      <Input
        key={"hhFinEtapa2b"}
        sets={{
          label: "Hora",
          type: "number",
          name: "hhFinEtapa2b",
          placeholder: " ",
          valor: hhFinEtapa2b,
          disabled: soloLectura,
          min: "0",
          max: "23",
        }}
        onChange={onChange}
      />
      <Input
        key={"mmFinEtapa2b"}
        sets={{
          label: "Minutos",
          type: "number",
          name: "mmFinEtapa2b",
          placeholder: " ",
          valor: mmFinEtapa2b,
          disabled: soloLectura,
          min: "0",
          max: "59",
        }}
        onChange={onChange}
      />
      <br></br>
      <hr></hr>
      <b>Subasta Etapa 3 Fase 1:</b>
      <Input
        key={"fechaInicioEtapa3a"}
        sets={{
          label: "Inicio",
          type: "date",
          name: "fechaInicioEtapa3a",
          placeholder: " ",
          valor: fechaInicioEtapa3a,
          disabled: soloLectura,
        }}
        onChange={onChange}
      />
      <Input
        key={"hhInicioEtapa3a"}
        sets={{
          label: "Hora",
          type: "number",
          name: "hhInicioEtapa3a",
          placeholder: " ",
          valor: hhInicioEtapa3a,
          disabled: soloLectura,
          min: "0",
          max: "23",
        }}
        onChange={onChange}
      />
      <Input
        key={"mmInicioEtapa3a"}
        sets={{
          label: "Minutos",
          type: "number",
          name: "mmInicioEtapa3a",
          placeholder: " ",
          valor: mmInicioEtapa3a,
          disabled: soloLectura,
          min: "0",
          max: "59",
        }}
        onChange={onChange}
      />
      <br></br>
      <Input
        key={"fechaFinEtapa3a"}
        sets={{
          label: "Fin",
          type: "date",
          name: "fechaFinEtapa3a",
          placeholder: " ",
          valor: fechaFinEtapa3a,
          disabled: soloLectura,
        }}
        onChange={onChange}
      />
      <Input
        key={"hhFinEtapa3a"}
        sets={{
          label: "Hora",
          type: "number",
          name: "hhFinEtapa3a",
          placeholder: " ",
          valor: hhFinEtapa3a,
          disabled: soloLectura,
          min: "0",
          max: "23",
        }}
        onChange={onChange}
      />
      <Input
        key={"mmFinEtapa3a"}
        sets={{
          label: "Minutos",
          type: "number",
          name: "mmFinEtapa3a",
          placeholder: " ",
          valor: mmFinEtapa3a,
          disabled: soloLectura,
          min: "0",
          max: "59",
        }}
        onChange={onChange}
      />
      <br></br>
      <hr></hr>
      <b>Subasta Etapa 3 Fase 2:</b>
      <Input
        key={"fechaInicioEtapa3b"}
        sets={{
          label: "Inicio",
          type: "date",
          name: "fechaInicioEtapa3b",
          placeholder: " ",
          valor: fechaInicioEtapa3b,
          disabled: soloLectura,
        }}
        onChange={onChange}
      />
      <Input
        key={"hhInicioEtapa3b"}
        sets={{
          label: "Hora",
          type: "number",
          name: "hhInicioEtapa3b",
          placeholder: " ",
          valor: hhInicioEtapa3b,
          disabled: soloLectura,
          min: "0",
          max: "23",
        }}
        onChange={onChange}
      />
      <Input
        key={"mmInicioEtapa3b"}
        sets={{
          label: "Minutos",
          type: "number",
          name: "mmInicioEtapa3b",
          placeholder: " ",
          valor: mmInicioEtapa3b,
          disabled: soloLectura,
          min: "0",
          max: "59",
        }}
        onChange={onChange}
      />
      <br></br>
      <Input
        key={"fechaFinEtapa3b"}
        sets={{
          label: "Fin",
          type: "date",
          name: "fechaFinEtapa3b",
          placeholder: " ",
          valor: fechaFinEtapa3b,
          disabled: soloLectura,
        }}
        onChange={onChange}
      />
      <Input
        key={"hhFinEtapa3b"}
        sets={{
          label: "Hora",
          type: "number",
          name: "hhFinEtapa3b",
          placeholder: " ",
          valor: hhFinEtapa3b,
          disabled: soloLectura,
          min: "0",
          max: "23",
        }}
        onChange={onChange}
      />
      <Input
        key={"mmFinEtapa3b"}
        sets={{
          label: "Minutos",
          type: "number",
          name: "mmFinEtapa3b",
          placeholder: " ",
          valor: mmFinEtapa3b,
          disabled: soloLectura,
          min: "0",
          max: "59",
        }}
        onChange={onChange}
      />
    </div>
  );
};

export default Calendario;
