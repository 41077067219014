import React from "react";
import ItemHeader from "../../layout/ItemHeader";

const HeaderAdmin = () => {
  return (
    <div className="navbar-nav">
      <ItemHeader label="Escritorio" to="dashboard" />
      <ItemHeader label="Subastas" to="missubastas" />
      <ItemHeader label="Catalogo General" to="catalogo" />

    </div>
  );
};

export default HeaderAdmin;
