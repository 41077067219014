// alerta
/* export const MOSTRAR_ALERTA = 'MOSTRAR_ALERTA';
export const OCULTAR_ALERTA = 'OCULTAR_ALERTA'; */

// auth
export const REGISTRO_EXITO = 'REGISTRO_EXITO';
export const REGISTRO_ERROR = 'REGISTRO_ERROR';
export const USUARIO_AUTENTICADO = 'USUARIO_AUTENTICADO';
export const LOGIN_EXITO = 'LOGIN_EXITO';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const CERRAR_SESION = 'CERRAR_SESION';
export const SELECCIONAR_USUARIO = 'SELECCIONAR_USUARIO';
export const ELIMINAR_USUARIO = 'ELIMINAR_USUARIO';
export const ORDENAR_FILTRAR_USUARIOS = 'ORDENAR_FILTRAR_USUARIOS';
export const DESELECCIONAR_USUARIO = 'DESELECCIONAR_USUARIO';


//usuarios
export const OBTENER_USUARIOS = 'OBTENER_USUARIOS';
export const ACTUALIZAR_USUARIO = 'ACTUALIZAR_USUARIO';

// personas
//export const ACTUALIZAR_PERSONA = 'ACTUALIZAR_PERSONA';
export const ELIMINAR_PERSONA = 'ELIMINAR_PERSONA';
export const SELECCIONAR_PERSONA = 'SELECCIONAR_PERSONA';
export const DESELECCIONAR_PERSONA = 'DESELECCIONAR_PERSONA';
export const OBTENER_PERSONAS = 'OBTENER_PERSONAS';
export const CONSEGUIR_PERSONA = 'CONSEGUIR_PERSONA';
//export const ORDENAR_FILTRAR_PERSONAS = 'ORDENAR_FILTRAR_PERSONAS';
export const MODIFICAR_PERSONA_SELECCIONADA='MODIFICAR_PERSONA_SELECCIONADA';
export const SELECCIONAR_PRECALIFICAR = 'SELECCIONAR_PRECALIFICAR';
export const CARGANDO = 'CARGANDO';
