import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import clienteAxios from "../../config/axios";
import styled from "@emotion/styled";
import ItemHeader from "./ItemHeader";
import HeaderUsuario from "../perfiles/usuario/HeaderUsuario";
import HeaderAdmin from "../perfiles/admin/HeaderAdmin";

const DivItemHeader = styled.div`
  color: white !important;
`;

const Header = ({ usuario, cerrarSesion }) => {
  const history = useHistory();
  const [notificaciones, setNotificaciones] = useState({});

  const ContenedorHeader = styled.nav`
    background-color: lightgray !important;
    padding: 5px 5px 5px 5px;
  `;

  useEffect(() => {
    const notificar = async () => {
      if (usuario) {
        try {
          const result = await clienteAxios.get(
            "/Notificaciones/getTengoNotificaciones"
          );
          //console.log(result.data.misnotificaciones);
          setNotificaciones(result.data.misnotificaciones[0]);
        } catch (e) {}
      }
    };
    notificar();
  }, [usuario]);

  const colorCampana = () => {
    if (notificaciones) {
      if (notificaciones.notil === null && notificaciones.idnotif !== null) {
        return "red";
      }
    }
    return "lightgray";
  };

  const onClick = () => {
    history.push("/notificaciones");
  };

  return (
    <>
      <ContenedorHeader className="navbar navbar-expand-md navbar-light bg-light">
        <Link aria-label="Home" className="navbar-brand" to="/">
          <DivItemHeader>
            <img
              src="/assets/images/Logo-Pool-Color.png"
              height="100px"
              alt="logo"
            />
          </DivItemHeader>
        </Link>

        <>
          <button
            type="button"
            className="navbar-toggler"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            onClick={() => {
              document
                .getElementById("navbarCollapse")
                .classList.toggle("show");
            }}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-between"
            id="navbarCollapse"
          > 
            {usuario ? (
              <>
                {usuario.admin === "0" ? <HeaderUsuario /> : <HeaderAdmin />}

                <div className="navbar-nav">
                  <button onClick={onClick} className="menu-trigger">
                    <i
                      className="fa fa-bell"
                      style={{
                        color: colorCampana(),
                        fontSize: "26px",
                        padding: "6px",
                      }}
                    ></i>
                  </button>
                  <Link
                    aria-label="Cerrar Sesion"
                    to=""
                    onClick={() => {
                      cerrarSesion();
                      history.push("/");
                    }}
                    className="nav-item nav-link"
                  >
                    <DivItemHeader>Cerrar Sesion</DivItemHeader>
                  </Link>
                </div>
              </>
            ) : (
              <div className="navbar-nav">
                <ItemHeader label="Registrarse" to="registrate" />
                <ItemHeader label="Ingresar" to="login" />
              </div>
            )}
          </div>
        </>
      </ContenedorHeader>
    </>
  );
};

export default Header;
