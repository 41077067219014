import React from "react";
import { Boton, BotonCancelar, BotonTransparente } from "./Estilos";

export const Input = ({ sets, onChange }) => {
  const { label, type, name, placeholder, valor, disabled, requerido, min, max } = sets;
  return (
    <div className="form-group row p-2">
      <div className="col-sm-3">
        <label style={{ fontSize: "small" }} htmlFor={name}>
          {label}
        </label>
        <br></br>
      </div>
      <div className="col-sm-9">
        <input
          disabled={disabled}
          type={type}
          name={name}
          className={`form-control form-control-md  ${
            !valor && requerido ? "is-invalid" : null
          }`}
          id={name}
          placeholder={placeholder}
          onChange={onChange}
          value={valor}
          style={{ borderRadius: "15px" }}
          min={min}
          max={max}
        />
      </div>
    </div>
  );
};

export const TextArea = ({ sets, onChange }) => {
  const {
    label,
    name,
    placeholder,
    valor,
    rows,
    cols,
    disabled,
    requerido,
  } = sets;

  return (
    <div className="form-group row p-2">
      <div className="col-sm-3">
        <label style={{ fontSize: "small" }} htmlFor={name}>
          {label}
        </label>
        <br></br>
      </div>
      <div className="col-sm-9">
        <textarea
          rows={rows}
          cols={cols}
          name={name}
          disabled={disabled}
          className={`form-control form-control-md  ${
            !valor && requerido ? "is-invalid" : null
          }`}
          id={name}
          placeholder={placeholder}
          onChange={onChange}
          value={valor}
          style={{ borderRadius: "15px" }}
        ></textarea>
      </div>
    </div>
  );
};

export const Select = ({ sets, onChange }) => {
  const { label, name, valor, opciones, disabled, requerido } = sets;

  return (
    <div className="form-group row p-2">
      {label ? (
        <div className="col-sm-3">
          <label style={{ fontSize: "small" }} htmlFor={name}>
            {label}
          </label>
          <br></br>
        </div>
      ) : null}
      <div className="col-sm-9">
        <select
          disabled={disabled}
          name={name}
          className={`form-select form-select-md  ${
            (valor==="" || valor==="0") && requerido ? "is-invalid" : null
          }`}
          id={name}
          onChange={onChange}
          value={valor}
          style={{ borderRadius: "15px" }}
        >
          <option value="">Seleccione..</option>
          {opciones.map((opcion) => (
            <option key={opcion.value} value={opcion.value}>
              {opcion.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

// BOTONES

export const BotonSubmit = ({ label }) => {
  return (
    <div className="col-sm d-grid gap-2">
      <Boton type="submit" className="btn btn-primary">
        {label}
      </Boton>
    </div>
  );
};

export const BotoneraForm = ({
  funcionCancelar,
  valorfuncion,
  deshabilitado,
}) => {
  return (
    <div className="row">
      {!deshabilitado ? (
        <div className="col-sm">
          <BotonSubmit label="Guardar" />
        </div>
      ) : null}

      <div className="col-sm d-grid gap-2">
        <BotonCancelar
          type="button"
          className="btn btn-secondary"
          onClick={() => {
            funcionCancelar(valorfuncion);
          }}
        >
          {!deshabilitado ? "Cancelar" : "Cerrar"}
        </BotonCancelar>
      </div>
    </div>
  );
};

export const BotonEditar = ({ editar, item, label=null }) => {
  return (
    <button
      className="btn btn-info btn-sm"
      type="button"
      onClick={() => {
        editar(item);
      }}
    >
      {label ? label : <i className="fa fa-edit" style={{ color: "white" }}></i> }
    </button>
  );
};

export const BotonEliminar = ({ eliminar, item }) => {
  return (
    <button
      className="btn btn-danger btn-sm"
      type="button"
      onClick={() => {
        eliminar(item);
      }}
    >
      <i className="fa fa-trash"></i>
    </button>
  );
};

export const BotonVer = ({ mostrar, item }) => {
  return (
    <button
      className="btn btn-success btn-sm"
      type="button"
      onClick={() => {
        mostrar(item);
      }}
    >
      <i className="fa fa-eye"></i>
    </button>
  );
};

export const BotonTituloTabla = ({
  label,
  funcion,
  param,
  campoOrden,
  tipoOrden,
}) => {
  return (
    <>
      <BotonTransparente
        className="bg-transparent"
        onClick={() => {
          funcion(param);
        }}
      >
        {label}
      </BotonTransparente>
      {campoOrden === param ? (
        <i
          className={`fa fa-angle-${
            tipoOrden === "desc" ? "up" : "down"
          } fa-2x`}
        ></i>
      ) : null}
    </>
  );
};
