import React from "react";
import Evento from "./MiSubasta";
import { TituloTabla } from "../../layout/Estilos";
import { useHistory } from "..";

const ListarMisSubastas = ({
  items,
  setItemSeleccionado,
  usuario,
  tipo,
}) => {
  const history = useHistory();

  const editar = (item) => {
    setItemSeleccionado(item);
    localStorage.removeItem("idproducto");
    localStorage.removeItem("nombre");
    localStorage.removeItem("idrubro");
    localStorage.removeItem("idsolicitud");
    //console.log(item);
    localStorage.setItem("idsubasta", item.id);

    if (usuario.esadmin === "1") {
      history.push("admin/gestionsubastas");
    } else {
      history.push("usuario/gestionsubastas");
    }
  };

  const eliminar = (item) => {
    history.push(`usuario/ofertarproveedor/${item.id}/${usuario.id}`);
  };

  const interes = (item) => {
    history.push(`catalogo/${item.id}`);
  };


  return (
    <div className="table-responsive">
      <table className="table" style={{ backgroundColor: "white" }}>
        <TituloTabla>
          <tr>
            <th scope="col" className="text-center">
              Subasta
            </th>
            <th scope="col" className="text-center">
              Etapa
            </th>
            <th scope="col" className="text-center">
              Acciones
            </th>
          </tr>
        </TituloTabla>
        <tbody>
          {items.map((item) => (
            <Evento
              key={item.id}
              item={item}
              editar={editar}
              eliminar={eliminar}
              usuario={usuario}
              tipo={tipo}
              interes={interes}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListarMisSubastas;
