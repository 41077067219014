import React from "react";
import { Link } from "react-router-dom";

const DasboardAdmin = () => {
  return (
    <div className="p-2 row justify-content-center ">
      <h2>Panel Administrador</h2>
      <div className="card" style={{ width: "90%", margin: "10px" }}>
        <div className="card-body">
          <h5 className="card-title">Configuraciones</h5>
          <p className="card-text">
            <Link to="admin/rubros/">Rubros</Link>
            <br></br>
            <Link to="admin/productos/">Ofertas</Link>
            <br></br>
            <Link to="admin/solicitudes/">Solicitudes</Link>
            <br></br>
            <Link to="notificaciones/">Notificaciones</Link>
          </p>
        </div>
      </div>
      <div className="card" style={{ width: "90%", margin: "10px" }}>
        <div className="card-body">
          <h5 className="card-title">Estadisticas </h5>
          <p className="card-text">Usuarios</p>
          <p className="card-text">Proveedores</p>
          <p className="card-text">Compradores</p>
        </div>
      </div>
    </div>
  );
};

export default DasboardAdmin;
