import React from "react";

const Terminos = () => {
  return (
    <>
      <br></br>

      <div className="row text-center" style={{ margin: "auto" }}>
        <h2 className="text-center">Terminos y Condiciones</h2>
      </div>
    </>
  );
};

export default Terminos;
