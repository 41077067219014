import React, { useEffect, useState } from "react";
import { clienteAxios, Link, Portal, Spinner } from "..";
import ResumenEtapaUsuario from "./ResumenEtapaUsuario";
import Form from "./ResumenOferentes/ResumenOferentes";

const PanelUsuario = () => {
  const [loadingLocal, setLoadingLocal] = useState(null);

  const [consultar, setConsultar] = useState(true);
  const [itemSeleccionado, setitemSeleccionado] = useState(null);
  const [productoSeleccionado, setproductoSeleccionado] = useState(null);

  //modal
  const [isOpened, setOpened] = useState(false);
  const openModal = () => {
    setOpened(true);
    window.scrollTo(0, 0);
  };
  const closeModal = () => {
    //if (cierro) {
    setOpened(false);
    //}
    //setConsultar(true);
    //setemailSeleccionado(null);
  };
  //modal

  useEffect(() => {
    const cargar = async () => {
      if (!consultar) return;

      try {
        setLoadingLocal(true);
        const result = await clienteAxios.get("/Subastas/getResumenProveedor", {
          params: {
            idsubasta: localStorage.getItem("idsubasta")
              ? localStorage.getItem("idsubasta")
              : "0",
          },
        });
        console.log(result.data);
        setitemSeleccionado({
          subasta: result.data.subasta[0],
          resumen: result.data.resumen,
        });
        setLoadingLocal(null);
      } catch (e) {}
      setConsultar(null);
    };

    cargar();
  }, [consultar, itemSeleccionado]);

  return (
    <div className="p-2 row justify-content-center ">
      <div className="text-center">
        <br></br>
        <Link to={"/missubastas"}>Volver a Subasta</Link>
        <br></br>
      </div>
      <h2>Panel Subastas</h2>

      {loadingLocal ? <Spinner /> : null}
      {itemSeleccionado ? (
        <>
          <h5 className="card-title">
            Resumen Subasta: {itemSeleccionado.subasta.titulo}
          </h5>
          <p></p>
          <ResumenEtapaUsuario
            etapa={itemSeleccionado.resumen.etapa2}
            idetapa="2"
          />
          <ResumenEtapaUsuario
            etapa={itemSeleccionado.resumen.etapa3}
            idetapa="3"
          />
          <ResumenEtapaUsuario
            etapa={itemSeleccionado.resumen.etapa45}
            idetapa="45"
          />
          <ResumenEtapaUsuario
            etapa={itemSeleccionado.resumen.etapa67}
            idetapa="67"
          />
          <ResumenEtapaUsuario
            etapa={itemSeleccionado.resumen.etapa8}
            openModal={openModal}
            idetapa="8"
            setproductoSeleccionado={setproductoSeleccionado}
          />

          <Portal isOpened={isOpened} onClose={closeModal}>
            <Form
              idetapa="8"
              closeModal={closeModal}
              idsubasta={itemSeleccionado.subasta.id}
              productoSeleccionado={productoSeleccionado}
            />
          </Portal>
        </>
      ) : null}

      <div className="text-center">
        <br></br>
        <Link to={"/missubastas"}>Volver a Subasta</Link>
        <br></br>
      </div>
    </div>
  );
};

export default PanelUsuario;
