import React from "react";
import Item from "./Notificacion";
import { TituloTabla } from "../layout/Estilos";
import { clienteAxios, Swal } from ".";

const ListarNotificaciones = ({
  emails,
  setemailSeleccionado,
  setConsultar,
  openModal,
  soloLectura,
}) => {
 
  const borrar = async (id) => {
    try {
      await clienteAxios.delete("/Rubros/remove", {
        params: { id },
      });
      setConsultar(true);
    } catch (e) {}
  };

  const editar = (email) => {
    setemailSeleccionado(email);
    openModal();
  };

  const eliminar = (email) => {
    Swal.fire({
      title: "Esta Seguro que desea Eliminar?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        borrar(email.id);
      }
    });
  };

  return (
    <div className="table-responsive">
      <table
        className="table table-striped"
        style={{ backgroundColor: "white" }}
      >
        <TituloTabla>
          <tr>
            <th scope="col" className="text-center">
              Fecha
            </th>
            <th scope="col" className="text-center">
              asunto
            </th>
            <th scope="col" className="text-center">
              Estado
            </th>
            <th scope="col" className="text-center">
              Acciones
            </th>
          </tr>
        </TituloTabla>
        <tbody>
          {emails.map((item) => (
            <Item
              key={item.id}
              item={item}
              editar={editar}
              eliminar={eliminar}
              soloLectura={soloLectura}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListarNotificaciones;
