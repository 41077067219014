import React, { useState, useEffect } from "react";
import Listar from "./ListarOfertas";
import { Spinner, clienteAxios, Portal } from ".";
import { BotonAgregar } from "../../layout/Estilos";
import Form from "./FromOferta";

const OfertaUsuario = ({ id, soloLectura, subasta }) => {
  const [loadingLocal, setLoadingLocal] = useState(null);
  const [emails, setEmails] = useState([]);

  const [consultar, setConsultar] = useState(true);
  const [itemSeleccionado, setitemSeleccionado] = useState(null);

  //modal
  const [isOpened, setOpened] = useState(false);
  const openModal = () => {
    setOpened(true);
    window.scrollTo(0, 0);
  };
  const closeModal = (cierro) => {
    if (cierro) {
      setOpened(false);
    }
    setConsultar(true);
    setitemSeleccionado(null);
  };
  //modal

  useEffect(() => {
    const cargar = async () => {
      if (!consultar) return;

      try {
        setLoadingLocal(true);
        const result = await clienteAxios.get("/SubastasProductos/getAllUser", {
          params: { idsubasta: subasta.id },
        });
        //console.log(result.data);
        setEmails(result.data.productos);
        setLoadingLocal(null);
      } catch (e) {}
      setConsultar(null);
    };
    //console.log(subasta);
    cargar();
  }, [consultar, itemSeleccionado, subasta.id]);

  const permitirAgregar = () => {
    //|| subasta.idetapa === "4" || subasta.idetapa === "6"
    //console.log(emails.filter((item) => item.idetapa === subasta.idetapa).length);

    if (
      (subasta.idetapa === "2" ||
      subasta.idetapa === "3" ||
        subasta.idetapa === "4" ||
        subasta.idetapa === "6") &&
      emails.filter((item) => item.idetapa === subasta.idetapa).length < 3
    ) {
      return true;
    }

    return false;
  };

  const etapa = (id) => {
    //console.log(precios);
    switch (id) {
      case "1":
        return "Programada";
      case "2":
        return "Recepcion de Ofertas";
      case "3":
        return "Etapa 1";
      case "4":
        return "Etapa 2 - 1";
      case "5":
        return "Etapa 2 - 2";
      case "6":
        return "Etapa 3 - 1";
      case "7":
        return "Etapa 3 - 2";
      default:
        break;
    }
  };


  return (
    <div className="center-block">
      <br></br>
      <h2>Gestion de Productos para Ofertar en Subasta</h2>
      <p>Etapa de la Subasta: {etapa(subasta.idetapa)} (Solo se puede Ofertar en las Etapas: Recepcion Ofertas, Etapa 2 - 1 y Etapa 3 - 1)</p>
      <div className="row">
        {!soloLectura ? (
          <div className="col-sm">
            <div className="form-group">
              {!isOpened && permitirAgregar() ? (
                <BotonAgregar
                  onClick={() => {
                    openModal();
                  }}
                  className="btn btn-dark"
                >
                  <i className="fa fa-plus-circle"></i> Crear Oferta
                </BotonAgregar>
              ) : null}
            </div>
          </div>
        ) : null}

        <br></br>
      </div>

      <br></br>
      {loadingLocal || !emails ? (
        <Spinner />
      ) : (
        <Listar
          emails={emails}
          setemailSeleccionado={setitemSeleccionado}
          setConsultar={setConsultar}
          openModal={openModal}
          soloLectura={soloLectura}
          etapaSubasta={subasta.idetapa}
        />
      )}

      <Portal isOpened={isOpened} onClose={closeModal}>
        <Form
          idseleccion={id}
          closeModal={closeModal}
          emailSeleccionado={itemSeleccionado}
          subasta={subasta}
        />
      </Portal>
    </div>
  );
};

export default OfertaUsuario;
