import React from "react";
import Proveedor from "./Proveedor";

const Proveedores = ({ proveedores, SetProveedorSeleccionado, SetConsultar, setArticulos }) => {
  const mostrar = (item) => {
    setArticulos(null);
    SetConsultar(true);
    SetProveedorSeleccionado(item);
  };

  return (
    <>
      {proveedores ? (
        <div className="p-2 row justify-content-center ">
        
          <br></br> <br></br>
          <br></br>
          {proveedores.map((item) => (
            <Proveedor
              key={item.id}
              item={item}
              mostrar={mostrar}
            />
          ))}
        </div>
      ) : null}
    </>
  );
};

export default Proveedores;
