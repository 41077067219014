import React, { useState, useEffect } from "react";
import { Spinner, clienteAxios } from ".";
import RegistroPlanillaResumen from "./RegistroPlanillaResumen";

const PlanillaResumen = ({ id, idetapa }) => {
  const [loadingLocal, setLoadingLocal] = useState(null);
  const [emails, setEmails] = useState(null);

  const [consultar, setConsultar] = useState(true);

  useEffect(() => {
    const cargar = async () => {
      if (!consultar) return;

      try {
        setLoadingLocal(true);

        const result = await clienteAxios.get("/Subastas/getPlanillaResumen", {
          params: { idsubasta: !id ? "0" : id, idproducto: !localStorage.getItem('idproducto') ? "0" : localStorage.getItem('idproducto')},
        });
        setEmails({
          proveedores: result.data.proveedores,
          subastas: result.data.subasta[0],
          articulos: result.data.articulos.filter((articulo) => articulo.idetapa === idetapa),
        });
        console.log(result.data, result.data.articulos.filter((articulo) => articulo.idetapa === idetapa));
        setLoadingLocal(null);
      } catch (e) {}
      setConsultar(null);
    };

    cargar();
  }, [consultar, id, idetapa]);

  const estado = (id) => {
    //console.log(precios);
    switch (id.toString()) {
      case "1":
        return "PROGRAMADA";
      case "2":
        return "RECEPCION DE OFERTAS";
      case "3":
        return "EN ETAPA 1";
      case "4":
        return "EN ETAPA 2 - 1";
      case "5":
        return "EN ETAPA 2 - 2";
      case "6":
        return "EN ETAPA 3 - 1";
      case "7":
        return "EN ETAPA 3 - 2";
      case "8":
        return "SUBASTA FINALIZADA";
      default:
        break;
    }
  };

  return (
    <div className="center-block">
      <br></br>
      <br></br>
      {loadingLocal || !emails ? (
        <Spinner />
      ) : (
        <div>
          <h3>Etapa {estado(idetapa)}</h3>
          <div className="table-responsive">
            <table className="table" style={{ backgroundColor: "white" }}>
              <thead>
                <tr>
                  <th>Oferta Económica - Planilla Consolidada</th>
                  <th>Cdad.</th>
                  {emails.proveedores.map((item) => (
                    <th key={item.id} className="text-center">
                      <img
                        alt="logo"
                        src={`https://pooldecompras.cimcc.org.ar/Api/writable/uploads/${item.logo}`}
                        height="50px"
                      ></img>
                      <br></br>
                      {item.razonsocial}
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody>
                <RegistroPlanillaResumen
                  subasta={emails.subastas}
                  articulos={emails.articulos}
                />
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default PlanillaResumen;
