import React from "react";
import { NavLink } from "react-router-dom";
import styled from "@emotion/styled";


const DivItemHeader = styled.div`
  color: white !important;
  font-size: medium;
  padding-botton: 5px;
`;

const ItemHeader = ({label, to}) => {
  return (
    <NavLink
      aria-label={label}
      to={`/${to}`}
      className="nav-item nav-link" 
      activeStyle={{
        textDecoration: "underline white",
      }}
    >
      <DivItemHeader>{label}</DivItemHeader>
    </NavLink>
  );
};

export default ItemHeader;
